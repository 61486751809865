import React from "react";
import WebView, { WebViewProps } from "react-native-webview";
//@ts-ignore
import SVGatorPlayer from "@svgator/react-native";
import MemberStorage, { color, personalTheme } from "../storage/MemberStorage";

//// SVG Data
import loading from "../../assets/images/svgs/LoadingAnimation";
import unlock from "../../assets/images/svgs/UnlockAnimation";
import lock from "../../assets/images/svgs/LockAnimation";
import thickLogoAnimation from "../../assets/images/svgs/ThickLogoAnimation";
import UI from "./UI";
import LogAPI from "../LogAPI";

export type svgColor = color | undefined
export type svgData = { svg: (accentColor: svgColor, layoutColor: svgColor) => string, js: string, name: string }
export type forwardedSvgRefComponent = React.ForwardRefExoticComponent<svgProps>
export type playerRef = React.RefObject<WebView>
export interface svgProps extends WebViewProps {
    ref?: playerRef
    onMessage?: (event: any) => void
}


////////////////////////////// API to convert SVGs to JSX using: This API returns a JSX Component based on the getter function fired //////////////////////////////

// SVGator PlayerAPI is also available: 
// Create a Ref: const <refName>:any = createRef()
// Reference the Component: <ComponentName ref={<refName>}/>
// Use the PlayerAPI using the ref variable eg.: <refName>.current.injectJavaScript


abstract class SvgAnimationAPI extends UI {
    private static animations: any = {} // type fixing?
    private static svgToJSX(name: string, svg: string, js: string) {
        const svgData = svg + SVGatorPlayer.getPlayer("91c80d77") + js
        // console.log(' - - - - - - - - - - - - - -  - - - - - -  SvgAnimationAPI - loading:', svgData)
        const html = SVGatorPlayer.wrapPage(svgData)
        const INJECTEDJAVASCRIPT = `
        const meta = document.createElement('meta');
        meta.setAttribute('content', 'width=device-width, initial-scale=0.5, maximum-scale=0.5, user-scalable=0');
        meta.setAttribute('name', 'viewport');

        const title = document.createElement('title')
        title.innerText = "${name}"
        document.getElementsByTagName('head')[0].appendChild(meta);
        document.getElementsByTagName('head')[0].appendChild(title);
        `

        return React.forwardRef<WebView>((props, ref) => {
            if (!SVGatorPlayer.getWebViewProps) {
                console.warn("Your currently installed @svgator/react-native package is outdated. " +
                    "Please update it to the newest version. " +
                    "See more: https://www.npmjs.com/package/@svgator/react-native");
                const newProps = SVGatorPlayer.parseProps(props, html);
                return (
                    <WebView
                        ref={ref}
                        {...newProps}
                        source={{ html }}
                        containerStyle={{ flex: 0 }}
                        style={{ backgroundColor: "transparent", flex: 0 }}
                        injectedJavaScript={INJECTEDJAVASCRIPT}
                    />
                );
            }

            const { newProps, styles } = SVGatorPlayer.getWebViewProps(props, html);

            return (
                <WebView
                    ref={ref}
                    {...newProps}
                    source={{ html }}
                    containerStyle={styles.container}
                    style={styles.style}
                    injectedJavaScript={INJECTEDJAVASCRIPT}
                />
            );
        });
    }


    // Applies theme to svg
    private static readonly applyThemeToAnimation = (theme: personalTheme, animation: svgData) => {
        // this.logAPI.log('applyThemeToAnimation', 'Applying Theme to', animation)
        const { svg, js, name } = animation

        // if (name === 'zToLockAnimation' && theme.useDarkMode) { // color the logo animation black for darkMode and white for lightMode
        //     theme.accentColor = '##fefefe',
        //     theme.layoutColor = '##fefefe'
        // }

        // Use theme as colors in svg
        const themedSvg = svg(theme.accentColor, theme.layoutColor)
        // console.log(' - - - - - - - - - - - - - -  - - - - - -  SvgAnimationAPI - loading:', name, themedSvg.includes(theme.accentColor), themedSvg.includes(theme.layoutColor), theme.accentColor, theme.layoutColor)
        this.animations[name] = this.svgToJSX(name, themedSvg, js)
    }


    // Getters
    static readonly getLoadingAnimationJSX = () => { // final
        return {
            LoadingAnimation: this.animations[loading.name] as forwardedSvgRefComponent,
            onMessage: (event: any) => this.logAPI.log('getLoadingAnimationJSX', 'LoadingAnimationOverlay Event:', event.nativeEvent.data)
        }
    }
    static readonly getUnlockAnimationJSX = () => { // final
        return {
            UnlockAnimation: this.animations[unlock.name] as forwardedSvgRefComponent,
            duration: 2000, // in ms - reflected in svg animation
            onMessage: (event: any) => this.logAPI.log('getUnlockAnimationJSX', 'UnlockAnimation Event:', event.nativeEvent.data)
        }
    }
    static readonly getLockAnimationJSX = () => {
        return {
            LockAnimation: this.animations[lock.name] as forwardedSvgRefComponent,
            duration: 1500, // in ms - reflected in svg animation
            onMessage: (event: any) => this.logAPI.log('getLockAnimationJSX', 'UnlockAnimation Event:', event.nativeEvent.data)
        }
    }
    static readonly getThickLogoAnimationJSX = () => {
        return {
            ThickLogoAnimation: this.animations[thickLogoAnimation.name] as forwardedSvgRefComponent,
            onMessage: (event: any) => this.logAPI.log('getThickLogoAnimationJSX', 'LockAnimationOverlay Event:', event.nativeEvent.data)
        }
    }


    // Initialized readonly API and applies theme to all svgs
    static readonly initialize = (t0 = LogAPI.getT0()) => {
        const theme = MemberStorage.getTheme()
        // if (theme.accentColor === DEFAULT_ACCENT_COLOR && theme.layoutColor === DEFAULT_LAYOUT_COLOR) { // use white instead of default theme
        //     if (theme.useDarkMode) {
        //         theme = {
        //             useDarkMode: theme.useDarkMode,
        //             accentColor: '#ffffff',
        //             layoutColor: '#ffffff'
        //         }
        //     }
        //     else {
        //         theme = {
        //             useDarkMode: theme.useDarkMode,
        //             accentColor: '#fefefe',
        //             layoutColor: '#fefefe'
        //         }
        //     }
        // }

        const animations = [
            loading,
            unlock,
            lock,
            thickLogoAnimation,
        ]

        animations.forEach(animation => {
            this.applyThemeToAnimation(theme, animation)
        })

        this.logAPI.performance('initialize', t0, 'SvgAnimationAPI with theme:', JSON.stringify(theme))
    }

    static async executeAnimationWithWork(executeAnimation: () => void, animationDuration: number, work?: () => (Promise<void> | void)) {
        executeAnimation()
        let remaingAnimationTime = animationDuration
        if (work) {
            const startTime = Date.now()
            await work()
            const elapsed = Date.now() - startTime
            remaingAnimationTime = remaingAnimationTime - elapsed
        }
        await new Promise(resolve => setTimeout(resolve, remaingAnimationTime > 0 ? remaingAnimationTime : 0))
    }

    // SVGator Player Methods
    private static readonly commands: any = {
        play: {},
        pause: {},
        toggle: {},
        reverse: {},
        stop: {},
        restart: {},
        destruct: {},
        seekByFwd: { command: 'seekBy', param: 500 },
        seekByBack: { command: 'seekBy', param: -500 },
        seekHalf: { command: 'seek', param: 50 },
        seek: { command: 'seek', param: 50 },
    };

    static readonly execPlayerCommand = (ref: playerRef, command: playerCommand, commandParam: number | undefined) => {
        const _command = this.commands[command]['command'] || command
        const _commandParam = this.commands[command]['param'] ? commandParam : ''
        const js = `document
        && document.querySelector
        && document.querySelector('svg')
        && document.querySelector('svg').svgatorPlayer
        && document.querySelector('svg').svgatorPlayer['${_command}']
        && document.querySelector('svg').svgatorPlayer['${_command}'](${_commandParam});
        true;`

        // this.logAPI.log('execPlayerCommand', 'Executing SvgPlayer Command:', js, ref.current)
        ref.current?.injectJavaScript(js)
    }

    protected static logAPI = super.logAPI.extend('SvgAnimation.api')
}

SvgAnimationAPI.initialize()
type playerCommand = 'play' | 'pause' | 'toggle' | 'reverse' | 'stop' | 'restart' | 'destruct' | 'seek'

export default SvgAnimationAPI