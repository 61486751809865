import React from "react"
import { View, ViewStyle } from "react-native"
import GlobalStyles, { DEFAULT_VERTICAL_DISTANCE } from "../../styles/global-styles"
import { ThemeProps } from "../../styles/Theme"

interface SeparatorProps {
    big?: boolean
}
export const separatorHeight = DEFAULT_VERTICAL_DISTANCE * 2
export const bigSeparatorHeight = separatorHeight * 2
const Separator = React.memo(({ big }: SeparatorProps) => <View style={[big ? GlobalStyles.bigVerticalPadding : GlobalStyles.verticalPadding]} />)
export default Separator




interface SeparatorWithBorderProps extends ThemeProps {
    borderAccentColor?: boolean
    big?: boolean
}
export const separatorWithBorderHeight = separatorHeight + GlobalStyles.borderBottom.borderBottomWidth
export const bigSeparatorWithBorderHeight = bigSeparatorHeight + GlobalStyles.borderBottom.borderBottomWidth
export const SeparatorWithBorder = React.memo(({ theme, borderAccentColor, big }: SeparatorWithBorderProps) => <View style={[GlobalStyles.borderBottom, borderAccentColor ? theme.styles.borderAccentColor : theme.styles.borderLayoutColor, big ? { ...GlobalStyles.bigMarginBottom, ...GlobalStyles.bigPaddingBottom } : { ...GlobalStyles.marginBottom, ...GlobalStyles.paddingBottom }, GlobalStyles.borderRadius]} />)