import React, { PropsWithChildren, useEffect } from "react"
// @ts-ignore
// import Loading from '../../assets/videos/loading.mp4'

import { Freeze } from "react-freeze"
import { BackHandler, StyleSheet, ViewStyle } from "react-native"
import Animated, { BaseAnimationBuilder, Easing, EntryExitAnimationFunction, FadeIn, FadeOut, ReduceMotion, WithSpringConfig, WithTimingConfig, useAnimatedStyle, useSharedValue, withSpring, withTiming } from "react-native-reanimated"
import { ReanimatedKeyframe } from "react-native-reanimated/lib/typescript/reanimated2/layoutReanimation/animationBuilder/Keyframe"
import DeviceAPI from "../../../apis/device/DeviceAPI"
import { color } from "../../../apis/storage/MemberStorage"
import StateAPI from "../../../apis/ui/StateAPI"
import { keyboardAvoidingAnimationConfig } from "../KeyboardAvoidingView"
import { PLATFORM } from "../../../env"

interface Props {
    dismiss?: () => void
    left?: number
    right?: number
    top?: number
    bottom?: number
    backgroundColor?: color
    zIndex?: number
    height?: number | `${number}%` | null | 'auto' // null to remove auto-fullscreen
    width?: number | `${number}%` | null | 'auto' // null to remove auto-fullscreen
    orientation?: 'center' | 'top' | 'bottom' | 'left' | 'right' | 'space-around' | 'space-between'
    onTouch?: () => void
    // animated?: {
    //     enteringAnimation: animation
    //     exitingAnimation: animation
    // }
    flexDirection?: 'column' | 'row'
    display?: boolean,
    style?: ViewStyle
}

type animation = EntryExitAnimationFunction | BaseAnimationBuilder | typeof BaseAnimationBuilder | ReanimatedKeyframe


const OverlayContainer: React.FC<PropsWithChildren<Props>> = ({ children, dismiss, left, right, top, bottom, backgroundColor, zIndex, height, width, orientation, onTouch, flexDirection, display, style: _style }) => {
    const style: ViewStyle = {
        zIndex: zIndex,
        left,
        right,
        backgroundColor
    }
    if (height === null) style.height = undefined
    else style.height = height
    if (width === null) style.width = undefined
    else style.width = width


    if (orientation) {
        if (orientation === 'top') {
            style.justifyContent = 'flex-start'
        }
        else if (orientation === 'bottom') {
            style.justifyContent = 'flex-end'
        }
        else if (orientation === 'left') {
            style.alignItems = 'flex-start'
        }
        else if (orientation === 'right') {
            style.alignItems = 'flex-end'
        }
        else style.justifyContent = orientation
    }
    if (flexDirection) style.flexDirection = flexDirection


    const animatedSwipeBottom = useSharedValue(0)
    const animatedBottom = useAnimatedStyle(() => {
        if (typeof bottom === 'number') return { bottom: withTiming(animatedSwipeBottom.value ? bottom - animatedSwipeBottom.value : bottom, keyboardAvoidingAnimationConfig) }
        return { bottom: undefined }
    })
    const animatedTop = useAnimatedStyle(() => {
        if (typeof top === 'number') return { top: withTiming(animatedSwipeBottom.value ? top + animatedSwipeBottom.value : top, keyboardAvoidingAnimationConfig) }
        return { top: undefined }
    })


    const shouldDisplay = typeof display === 'boolean' ? display : true


    useEffect(() => {
        if (dismiss) {
            const subscription = DeviceAPI.addHardwareBackPressListener(() => {
                if (shouldDisplay) {
                    dismiss()
                    return true
                }
            })
            return () => {
                subscription?.remove()
            }
        }
    }, [dismiss, shouldDisplay])



    return (
        <Freeze freeze={!shouldDisplay}>
            <Animated.View
                entering={PLATFORM !== 'web' ? FadeIn : undefined} // react-native-reanimated prebuilt animations do not work in react-native-web(https://github.com/software-mansion/react-native-reanimated/issues/598)
                exiting={PLATFORM !== 'web' ? FadeOut : undefined}
                style={[
                    styles.container,
                    style,
                    _style,
                    animatedBottom,
                    animatedTop
                ]}
                onTouchEnd={onTouch}
            >
                {children}
            </Animated.View>
        </Freeze>
    )
}

const styles = StyleSheet.create({
    container: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        // overflow: 'hidden'
    }
})

export default React.memo(OverlayContainer)