import { tsParticles, type Engine } from "@tsparticles/engine";
import { loadSlim } from "@tsparticles/slim";
import { loadBasic } from "@tsparticles/basic";
import React, { useEffect } from 'react';
import { View } from 'react-native';
import { isLandscapeOrientation, screenHeight, screenWidth } from "../../../client/env";
import { ThemeProps } from "../../../client/styles/Theme";
import GlobalStyles from '../../../client/styles/global-styles';
import OverlayContainer from "../../../client/components/Assets/Overlays/OverlayContainer";
import { loadAll } from "@tsparticles/all";


const ParticlesBackground: React.FC<ThemeProps> = ({ theme }) => {
    useEffect(() => {
        const memberLinks = {
            enable: true,
            distance: isLandscapeOrientation ? screenHeight / 4 : screenWidth / 2,
            frequency: 2,
            color: theme.colors.accentColor,
            width: 2,
            id: 'member-links',
            // triangles: {
            //   enable: true,
            //   color: theme.colors.lightAccent,
            //   opacity: 0.02,
            // }
        }
        const bigMemberLinks = {
            ...memberLinks,
            distance: memberLinks.distance / 2,
            width: memberLinks.width * 2
        }
        const nodeLinks = {
            enable: true,
            distance: isLandscapeOrientation ? screenHeight / 3 : screenWidth / 1.5,
            frequency: 100,
            id: 'node-links',
            color: theme.colors.layoutColor,
            // opacity: 0.4,
            width: 2,
        }
        const bigNodeLinks = {
            ...nodeLinks,
            distance: nodeLinks.distance / 2,
            width: nodeLinks.width * 2
        }
        const shape = { type: 'circle' }
        const optimalNumberOfParticles = Math.ceil(screenWidth * screenHeight / (isLandscapeOrientation ? screenWidth * 12 : screenHeight * 10))

        const numberValue = Math.ceil(optimalNumberOfParticles / 4)



        const EN_MN_small = {
            number: { value: numberValue, },
            color: { value: [theme.colors.accentColor, theme.colors.layoutColor] },
            shape,
            links: nodeLinks
        }
        const MEMBER_EN_small = {
            number: { value: numberValue, },
            color: { value: [theme.colors.lightAccent, theme.colors.accentColor] },
            shape,
            links: memberLinks
        }
        const EN_MN_big = {
            number: { value: numberValue, },
            color: { value: [theme.colors.accentColor, theme.colors.layoutColor] },
            shape,
            links: bigNodeLinks
        }
        const MEMBER_EN_big = {
            number: { value: numberValue, },
            color: { value: [theme.colors.lightAccent, theme.colors.accentColor] },
            shape,
            links: bigMemberLinks
        }

        const config = {
            id: "tsparticles",
            options: {
                fpsLimit: 5,
                particles: {
                    number: {
                        value: optimalNumberOfParticles,
                    },
                    size: {
                        value: { min: 2, max: 6 }
                    },
                    groups: {
                        EN_MN_small,
                        MEMBER_EN_small,
                        EN_MN_big,
                        MEMBER_EN_big,
                    },
                    // repulse: {
                    //   enable: true,
                    //   distance: 400
                    // },
                    // effect: {
                    //   close: false
                    // },
                    move: {
                        enable: true,
                        speed: 0.05,
                        moveOut: 'bounce' as const
                    }
                },
                // interactivity: {
                //     detect: 'canvas',
                //     events: {
                //         onHover: {
                //             enable: true,
                //             mode: "repulse" as const
                //         },
                //         // onClick: {
                //         //   enable: true,
                //         //   mode: "push" as const
                //         // }
                //     },
                //     modes: {
                //         repulse: {
                //             distance: midNumber
                //         },
                //         // push: {
                //         //   quantity: 4
                //         // }
                //     }
                // },
                background: {
                    color: theme.colors.dark
                },
                // retina_detect: true,
            }
        };


        (async (engine: Engine) => {
            await loadSlim(engine);

            await engine.load(config);
        })(tsParticles);
    }, [theme])

    return (
        <>
            <View style={[GlobalStyles.screenHeight, GlobalStyles.screenWidth, { position: 'absolute', zIndex: -1 }, theme.styles.backgroundDark]}>
                <div id="tsparticles"></div>
            </View>
            <OverlayContainer top={0} left={0} width={screenWidth} height={screenHeight} backgroundColor={theme.colors.transparentDark} style={{ opacity: 0.6 }} />
        </>
    )
}

export default React.memo(ParticlesBackground)