import { StyleSheet } from "react-native";
import { DEFAULT_BIG_FONT_SIZE, DEFAULT_BIG_LINE_HEIGHT, DEFAULT_FONT, DEFAULT_FONT_SIZE, DEFAULT_LINE_HEIGHT, DEFAULT_SMALL_FONT_SIZE, DEFAULT_SMALL_LINE_HEIGHT, DEFAULT_VERY_BIG_FONT_SIZE, DEFAULT_VERY_BIG_LINE_HEIGHT, DEFAULT_VERY_SMALL_FONT_SIZE, DEFAULT_VERY_SMALL_LINE_HEIGHT, isLandscapeOrientation, screenHeight, screenWidth } from "../env";

export const DEFAULT_HORIZONTAL_DISTANCE = isLandscapeOrientation ? screenWidth * 0.0085 : screenWidth * 0.02
export const DEFAULT_VERTICAL_DISTANCE = screenHeight * 0.004
export const DEFAULT_BORDER_RADIUS = 5

const GlobalStyles = StyleSheet.create({
    heading: {
        fontSize: DEFAULT_BIG_FONT_SIZE,
        lineHeight: DEFAULT_BIG_LINE_HEIGHT,
        fontFamily: DEFAULT_FONT
    },
    bigHeading: {
        fontSize: DEFAULT_VERY_BIG_FONT_SIZE,
        lineHeight: DEFAULT_VERY_BIG_LINE_HEIGHT,
        fontFamily: DEFAULT_FONT
    },
    text: {
        fontSize: DEFAULT_FONT_SIZE,
        lineHeight: DEFAULT_LINE_HEIGHT,
        fontFamily: DEFAULT_FONT
    },
    smallText: {
        fontSize: DEFAULT_SMALL_FONT_SIZE,
        lineHeight: DEFAULT_SMALL_LINE_HEIGHT,
        fontFamily: DEFAULT_FONT
    },
    verySmallText: {
        fontSize: DEFAULT_VERY_SMALL_FONT_SIZE,
        lineHeight: DEFAULT_VERY_SMALL_LINE_HEIGHT,
        fontFamily: DEFAULT_FONT
    },
    underlinedText: {
        textDecorationLine: 'underline'
    },
    boldText: {
        fontWeight: '600'
    },
    leanText: {
        fontWeight: '300'
    },
    textAlignCenter: {
        textAlign: 'center'
    },
    textAlignRight: {
        textAlign: 'right'
    },

    border: {
        borderStyle: 'solid',
        borderWidth: 1
    },
    borderLeftBottom:{
        borderStyle: 'solid',
        borderLeftWidth: 1,
        borderBottomWidth: 1,
    },
    borderTop: {
        borderStyle: 'solid',
        borderTopWidth: 1
    },
    borderBottom: {
        borderStyle: 'solid',
        borderBottomWidth: 1
    },
    borderLeft: {
        borderStyle: 'solid',
        borderLeftWidth: 1
    },
    borderLeftTopRight: {
        borderStyle: 'solid',
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderTopWidth: 1,
    },
    borderRadius: {
        borderRadius: DEFAULT_BORDER_RADIUS
    },
    borderLeftRight: {
        borderStyle: 'solid',
        borderLeftWidth: 1,
        borderRightWidth: 1,
    },


    flex: {
        flex: 1
    },
    flexBetween: {
        display: "flex",
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row'
    },
    flexAround: {
        justifyContent: 'space-around',
        alignItems: 'center',
        flexDirection: 'row'
    },
    flexCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    flexStart: {
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'row'
    },
    flexEnd: {
        justifyContent: 'flex-end',
        alignItems: 'center',
        flexDirection: 'row'
    },
    flexStartNoCenter: {
        justifyContent: 'flex-start',
        flexDirection: 'row'
    },
    flexAlignCenter: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    flexJustifyCenter: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center"
    },
    flexDirectionColumn: {
        flexDirection: "column",
    },
    flexDirectionRow: {
        flexDirection: "row",
    },


    padding: {
        paddingHorizontal: DEFAULT_HORIZONTAL_DISTANCE,
        paddingVertical: DEFAULT_VERTICAL_DISTANCE
    },
    paddingLeft: {
        paddingLeft: DEFAULT_HORIZONTAL_DISTANCE
    },
    paddingRight: {
        paddingRight: DEFAULT_HORIZONTAL_DISTANCE
    },
    paddingTop: {
        paddingTop: DEFAULT_VERTICAL_DISTANCE
    },
    paddingBottom: {
        paddingBottom: DEFAULT_VERTICAL_DISTANCE
    },
    horizontalPadding: {
        paddingHorizontal: DEFAULT_HORIZONTAL_DISTANCE
    },
    verticalPadding: {
        paddingVertical: DEFAULT_VERTICAL_DISTANCE
    },
    bigPadding: {
        paddingHorizontal: DEFAULT_HORIZONTAL_DISTANCE * 3,
        paddingVertical: DEFAULT_VERTICAL_DISTANCE * 3
    },
    bigPaddingLeft: {
        paddingLeft: DEFAULT_HORIZONTAL_DISTANCE * 3
    },
    bigPaddingRight: {
        paddingRight: DEFAULT_HORIZONTAL_DISTANCE * 3
    },
    bigPaddingTop: {
        paddingTop: DEFAULT_VERTICAL_DISTANCE * 3
    },
    bigPaddingBottom: {
        paddingBottom: DEFAULT_VERTICAL_DISTANCE * 3
    },
    bigHorizontalPadding: {
        paddingHorizontal: DEFAULT_HORIZONTAL_DISTANCE * 3
    },
    bigVerticalPadding: {
        paddingVertical: DEFAULT_VERTICAL_DISTANCE * 3
    },



    verticalMargin: {
        marginVertical: DEFAULT_VERTICAL_DISTANCE
    },
    horizontalMargin: {
        marginHorizontal: DEFAULT_HORIZONTAL_DISTANCE
    },
    margin: {
        marginVertical: DEFAULT_VERTICAL_DISTANCE,
        marginHorizontal: DEFAULT_HORIZONTAL_DISTANCE
    },
    marginTop: {
        marginTop: DEFAULT_VERTICAL_DISTANCE
    },
    marginLeft: {
        marginLeft: DEFAULT_HORIZONTAL_DISTANCE
    },
    marginRight: {
        marginRight: DEFAULT_HORIZONTAL_DISTANCE
    },
    marginBottom: {
        marginBottom: DEFAULT_VERTICAL_DISTANCE
    },
    bigVerticalMargin: {
        marginVertical: DEFAULT_VERTICAL_DISTANCE * 3
    },
    bigHorizontalMargin: {
        marginHorizontal: DEFAULT_HORIZONTAL_DISTANCE * 3
    },
    bigMargin: {
        marginVertical: DEFAULT_VERTICAL_DISTANCE * 3,
        marginHorizontal: DEFAULT_HORIZONTAL_DISTANCE * 3
    },
    bigMarginTop: {
        marginTop: DEFAULT_VERTICAL_DISTANCE * 3
    },
    bigMarginLeft: {
        marginLeft: DEFAULT_HORIZONTAL_DISTANCE * 3
    },
    bigMarginRight: {
        marginRight: DEFAULT_HORIZONTAL_DISTANCE * 3
    },
    bigMarginBottom: {
        marginBottom: DEFAULT_VERTICAL_DISTANCE * 3
    },




    width100: {
        width: '100%'
    },
    height100: {
        height: '100%'
    },
    screenWidth: {
        width: screenWidth
    },
    screenHeight: {
        height: screenHeight
    }
})

export default GlobalStyles