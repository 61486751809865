// Mock Implementation

export default abstract class Qunai {
    static readonly MIN_ROUTE_EVENT_NAME = 0
}

export type accountType = 'Member' | 'Leader' | 'Visionary'

export type eventName = number

export type hash = Buffer

export type base64 = string
export const Base64: any = {}

export class RegistryAuth {
    static fromBinary(arg: any) {
        return new RegistryAuth()
    }
    toCompressedBase64WithPrivatePaymentKey(arg: any) {
        return ''
    }
}

export const oneMinuteInMs = 60000

export const ED25519: any = {}
export type ED25519_publicIdentityKey = Uint8Array
export type ED25519_privateIdentityKey = Uint8Array

export const TypedArray: any = {}

export const ZLIB: any = {}

export const createLogAPI = () => class LogAPI {
    private className: string
    protected constructor(className: string) {
        this.className = className
    }
    log(functionName: string, ...args: any[]) {
        console.log(this.className, functionName, ...args)
    }
    error(functionName: string, ...args: any[]) {
        console.error(this.className, functionName, ...args)
    }
    performance(functionName: string, t0: number, ...args: any[]) {
        console.log(this.className, functionName, LogAPI.getT0() - t0, ...args)
    }
    static getT0() {
        return performance.now()
    }
    static create(className: string, logLevel: number) {
        return new LogAPI(className)
    }
    extend(className: string) {
        return new LogAPI(`${this.className}-${className}`)
    }
}


export const appName = 'Qunai' // IMPORTANT: has to be the same as client/mobile/app.json
export const lowerCaseAppName = appName.toLowerCase()
const SERVER_HOST = `${lowerCaseAppName}.io` as const
export const SUPPORT_EMAIL = `support@${SERVER_HOST}` as const
export const RESOURCE_SERVER_HOST = `resource.${SERVER_HOST}` as const
export const RESOURCE_SERVER_URI = `https://${RESOURCE_SERVER_HOST}` as const
export const WEB_CLIENT_SERVER_URI = `https://${SERVER_HOST}` as const

const randomStringChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
export abstract class CSPRNG {
    static generateUnsafeRandomString(length: number) {
        // Pick characters randomly
        let str = '';
        for (let i = 0; i < length; i++) {
            str += randomStringChars.charAt(Math.floor(Math.random() * randomStringChars.length));
        }
        return str;
    }

    /**
* Returns a random number(integer) between min (inclusive) and max (exclusive)
*/
    static generateUnsafeRandomInteger(min: number, max: number) {
        // return crypto.randomInt(min, max);
        return Math.round(Math.random() * (max - 1 - min) + min)
    }
}