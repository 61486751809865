import i18n from "i18next";
import 'intl-pluralrules';
import { initReactI18next } from "react-i18next";
import { Rationale } from "react-native";
import { MMKV } from "react-native-mmkv";
import { LANGUAGE_CODE, TRANSLATION_KEY_PATH_DELIMITER } from "../../env";
import type DeviceAPI from "../device/DeviceAPI";
import type ForegroundServiceAPI from "../notifications/ForegroundServiceAPI";
import { oneDayInMs } from "../payment/env/shared.env";
import UI from "./UI";
import LogAPI from "../LogAPI";
import en from "../../assets/languages/en.json"
import de from "../../assets/languages/de.json"

type languageName = 'Deutsch' | 'English'
export type language = 'en' | 'de'
export type customLanguage = language | undefined
export type searchTranslationItem = {
    path: string
    key: string
    value: string
}

const translationPath = 'apis.ui.LanguageAPI'
export { i18n }

export default abstract class LanguageAPI extends UI {
    static storage: MMKV
    protected static DeviceAPI: typeof DeviceAPI
    protected static ForegroundServiceAPI: typeof ForegroundServiceAPI

    static initialize<T extends {}>(deviceAPI: typeof DeviceAPI, foregroundServiceAPI: typeof ForegroundServiceAPI, additionalTranslations?: { en: T, de: T }, t0 = LogAPI.getT0()) {
        this.DeviceAPI = deviceAPI
        this.ForegroundServiceAPI = foregroundServiceAPI
        this.timeFormat = new Intl.DateTimeFormat(
            LanguageAPI.DeviceAPI.getLocale(),
            { hour: '2-digit', minute: '2-digit', second: undefined, timeZone: LanguageAPI.DeviceAPI.getTimezone(), hour12: !LanguageAPI.DeviceAPI.uses24HourClock() }
        )
        this.dateFormat = new Intl.DateTimeFormat(
            LanguageAPI.DeviceAPI.getLocale(),
            { month: '2-digit', year: '2-digit', day: '2-digit', timeZone: LanguageAPI.DeviceAPI.getTimezone() }
        )

        this.storage = new MMKV({ id: 'language' })
        const customLanguage = this.storage.getString(LANGUAGE_CODE) as customLanguage
        const [_en, _de] = additionalTranslations ? [{ ...en, ...additionalTranslations.en }, { ...de, ...additionalTranslations.de }] : [en, de]
        const resources = {
            en: { translation: _en },
            de: { translation: _de }
        }

        const config: any = {
            resources,
            fallbackLng: 'en',
            interpolation: {
                escapeValue: false // react-native already cleans input for xss prevention
            }
        }
        if (customLanguage) config.lng = customLanguage
        i18n.use(initReactI18next).init(config)
        this.logAPI.performance('initialize', t0, 'using Language', this.getLanguage())
    }
    static changeLanguage = async (language: [name: languageName, language: language]) => {
        const _language = language[1]
        i18n.changeLanguage(_language)
        this.logAPI.log('changeLanguage', JSON.stringify({ language: _language, storageExists: this.storage ? true : false }))
        if (this.storage) {
            this.storage.set(LANGUAGE_CODE, _language)
            await LanguageAPI.ForegroundServiceAPI.initialize()
        }
    }
    static getLanguage() {
        return i18n.language
    }
    static get: typeof i18n.t = i18n.t

    static getPermissionRationale(permission: 'CAMERA' | 'CONTACTS' | 'RECORD_AUDIO' | 'READ_EXTERNAL_STORAGE' | 'WRITE_EXTERNAL_STORAGE' | 'INSTALL_PACKAGES') {
        return {
            ...this.get(`${translationPath}.permissions.${permission}`, { returnObjects: true }),
            ...this.get(`${translationPath}.permissions.buttons`, { returnObjects: true })
        } as Rationale
    }


    static getSupportedLanguages(): [name: languageName, language: language][] {
        return [
            ["Deutsch", "de"],
            ["English", "en"],
            // [ "Français", "fr" ],
            // [ "Беларуская", "be" ],
            // [ "Español", "es" ],
        ]
    }
    private static timeFormat: Intl.DateTimeFormat
    private static dateFormat: Intl.DateTimeFormat
    private static formatTime(time: Date | number) {
        return this.timeFormat.format(time)
    }
    private static formatDate(date: Date | number) {
        return this.dateFormat.format(date)
    }
    static getDisplayDate(timestamp: number) {
        const now = Date.now()
        const nowDate = this.formatDate(now)
        const timestampDate = this.formatDate(timestamp)
        const timestampTime = this.formatTime(timestamp)
        if (nowDate === timestampDate) return `${this.get(`${translationPath}.today`)} ${timestampTime}`
        else if (nowDate > timestampDate) {
            const yesterdayDate = this.formatDate(now - oneDayInMs)
            if (nowDate === yesterdayDate) return `${this.get(`${translationPath}.yesterday`)} ${timestampTime}`
        }
        else {
            const tomorrowDate = this.formatDate(now + oneDayInMs)
            if (nowDate === tomorrowDate) return `${this.get(`${translationPath}.tomorrow`)} ${timestampTime}`
        }

        return `${timestampDate} ${timestampTime}`
    }
    static getDate(timestamp: number) {
        return `${this.formatDate(timestamp)} ${this.formatTime(timestamp)}`
    }


    static searchTranslationObject(searchQuery: string, rootObj: any, searchTips?: boolean) {
        let values: searchTranslationItem[] = []
        const searchNestedObjects = (obj: any, translationKeyPath: string) => {
            Object.keys(obj).forEach(key => {
                const nested = obj[key]
                if (typeof nested === 'object') searchNestedObjects(nested, translationKeyPath + TRANSLATION_KEY_PATH_DELIMITER + key) // recurse through nested objects
                else { //actual search
                    if (nested.toString().toLowerCase().includes(searchQuery)) { // matches query
                        const lowerCaseKey = key.toLowerCase()
                        const isNotTip = !(lowerCaseKey.includes('tip'))
                        const isNotExcluded = lowerCaseKey.substring(0, 2) !== '$$' // hidden in search

                        if ((searchTips === false ? true : isNotTip) && isNotExcluded) {
                            const translationKey = this.createTranslationDisplayKey(rootObj, nested, translationKeyPath)
                            const searchTranslationItem: searchTranslationItem = {
                                path: translationKeyPath,
                                key: translationKey,
                                value: nested
                            }
                            values.push(searchTranslationItem)
                        }
                    }
                }
            })
        }

        searchNestedObjects(rootObj, '')
        return values
    }
    private static createTranslationDisplayKey(obj: any, value: string, translationKeyPath: string) {
        let translationPath = ''
        let dynamicInitialObj = obj
        // this.logAPI.log('TranslationKeyPath:', translationKeyPath)
        // this.logAPI.log('Value:', value)

        translationKeyPath.split(TRANSLATION_KEY_PATH_DELIMITER).forEach((key, i) => { // construct translationPath using the keyPath delimiter
            // dynamicInitialObj = dynamicInitialObj[key].$title
            // translationPath += dynamicInitialObj

            const translation = dynamicInitialObj[key]
            if (translation && translation.$title) {
                dynamicInitialObj = translation
                translationPath += TRANSLATION_KEY_PATH_DELIMITER + translation.$title
                // this.logAPI.log('translationPath key', key)
                // this.logAPI.log('translationPath generation', translation)
            }
        })

        // this.logAPI.log('TranslationPath:', translationPath)
        return translationPath
    }

    protected static logAPI = super.logAPI.extend('Language.api')
}