import React, { useMemo } from "react";
import { DEFAULT_SMALL_ICON_SIZE } from "../../../env";
import { ThemeProps } from "../../../styles/Theme";
import Icon from "../Icon";
import OverlayContainer from "./OverlayContainer";

export interface ThemePropsWithSwipeableIndicator extends ThemeProps {
    displaySwipeableIndicator?: boolean | 'right' | 'left'
}


const SwipeableIndicatorOverlay: React.FC<ThemePropsWithSwipeableIndicator> = ({ theme, displaySwipeableIndicator }) => {

    if (displaySwipeableIndicator) {
        if (displaySwipeableIndicator === true) {
            return (
                <>
                    <SwipeableIndicatorOverlay theme={theme} displaySwipeableIndicator={'left'} />
                    <SwipeableIndicatorOverlay theme={theme} displaySwipeableIndicator={'right'} />
                </>
            )
        }
        else {
            return (
                <OverlayContainer bottom={-DEFAULT_SMALL_ICON_SIZE * 0.15} left={displaySwipeableIndicator === 'left' ? 0 : undefined} right={displaySwipeableIndicator === 'right' ? 0 : undefined}>
                    <Icon color={theme.colors.lightAccent} size={DEFAULT_SMALL_ICON_SIZE} iconSource='MaterialCommunity' name={`gesture-swipe-${displaySwipeableIndicator}`} />
                </OverlayContainer>
            )
        }
    }
}


export default React.memo(SwipeableIndicatorOverlay)