import { useCallback } from "react"

const useOnLinkPress = ({ download, href }: { download?: string, href: string }) => {
    return useCallback(() => {
        const link = document.createElement('a');
        if (download) link.download = download;
        link.href = href;
        link.target = "_blank"
        link.rel = "noopener noreferrer"
        link.click();
        link.remove()
    }, [download, href])
}

export default useOnLinkPress