import Clipboard from '@react-native-community/clipboard';
import { Dispatch, SetStateAction } from "react"
import { OK_BUTTON_TIMEOUT } from '../../env';
import Theme from '../../styles/Theme';

// Mock Implementation
export type clientAuth = boolean
export type setSwipePosition = Dispatch<SetStateAction<number>>
export type swipePosition = number
export type tab = 'main' | 'tech'
export interface ThemeProps{
    theme: Theme
}
export interface ThemePropsWithTips extends ThemeProps{
    displayTips: boolean
}

export default abstract class StateAPI {
    static executeAnimationFunctions = async (functionsWithAnimations: (() => any)[], timeout = 300) => {
        await new Promise<null>(resolve => {
            functionsWithAnimations.forEach((f, i) => {
                setTimeout(() => {
                    f()
                    if (i === functionsWithAnimations.length - 1) resolve(null)
                }, timeout * i)
            })
        })
    }
    
    static setDisplayLanguageSelection: (display: boolean) => void = () => {}
    static setLoading: (display: boolean) => void = () => {}
    static setLocking: (display: boolean) => void = () => {}

    static dismissKeyboard() { }
}
