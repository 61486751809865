import React, { PropsWithChildren, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyleProp, TouchableOpacity, View, ViewStyle } from "react-native";
import { TouchableOpacity as GestureHandlerTouchableOpacity } from "react-native-gesture-handler";
import Animated, { FadeIn, FadeInRight, FadeOut, FadeOutRight } from "react-native-reanimated";
import { color } from "../../apis/storage/MemberStorage";
import StateAPI from "../../apis/ui/StateAPI";
import { DEFAULT_BIG_ICON_SIZE, INCREASED_HIT_SLOP, screenHeight, screenWidth } from "../../env";
import useCoordinatedCallbacks from "../../hooks/useCoordinatedCallbacks";
import Theme, { ThemeProps } from "../../styles/Theme";
import GlobalStyles from "../../styles/global-styles";
import Icon, { FadingOkIcon, IconProps, IconText, TextIconProps } from "./Icon";
import { Text, TextProps, highlightableText } from "./Text";
import LanguageSelectionOverlay from "./Overlays/LanguageSelectionOverlay";
import DeviceAPI from "../../apis/device/DeviceAPI";


interface ContentButtonProps {
    onPress?: (value?: any) => void,
    onLongPress?: (value?: any) => void,
    /**
     * delay(in ms) before onLongPress fires
     */
    longPressDelay?: number
    disabled?: boolean,
    style?: StyleProp<ViewStyle>,
    color?: color
    increaseHitSlop?: true
}
interface ButtonProps extends ContentButtonProps {
    theme: Theme
}

interface GestureHandlerContentButtonProps extends ContentButtonProps {
    containerStyle?: StyleProp<ViewStyle>
}

export const GestureHandlerContentButton: React.FC<PropsWithChildren<GestureHandlerContentButtonProps>> = React.memo(({ onPress, onLongPress, longPressDelay, disabled, style, containerStyle, color, increaseHitSlop, children }) => {
    return (
        <GestureHandlerTouchableOpacity
            onPress={onPress}
            onLongPress={onLongPress}
            delayLongPress={longPressDelay}
            disabled={disabled || !(onPress || onLongPress)}
            style={[GlobalStyles.flexCenter, { backgroundColor: color }, GlobalStyles.borderRadius, style]}
            containerStyle={containerStyle}
            hitSlop={increaseHitSlop ? INCREASED_HIT_SLOP : undefined}
        >
            {children}
        </GestureHandlerTouchableOpacity>
    )
})
export const ContentButton: React.FC<PropsWithChildren<ContentButtonProps>> = React.memo(({ onPress, onLongPress, longPressDelay, disabled, style, color, increaseHitSlop, children }) => {
    return (
        <TouchableOpacity
            onPress={onPress}
            onLongPress={onLongPress}
            delayLongPress={longPressDelay}
            disabled={disabled || !(onPress || onLongPress)}
            style={[GlobalStyles.flexCenter, { backgroundColor: color }, GlobalStyles.borderRadius, style]}
            hitSlop={increaseHitSlop ? INCREASED_HIT_SLOP : undefined}
        >
            {children}
        </TouchableOpacity>
    )
})

interface TextButtonProps extends ButtonProps, TextProps { }
export const Button: React.FC<TextButtonProps> = React.memo(({ onPress, onLongPress, longPressDelay, disabled, style, color, increaseHitSlop, theme, text }) => {
    return (
        <ContentButton
            onPress={onPress}
            onLongPress={onLongPress}
            longPressDelay={longPressDelay}
            disabled={disabled || !(onPress || onLongPress)}
            style={[[GlobalStyles.borderLeftRight, GlobalStyles.padding, theme.styles.borderLight, GlobalStyles.borderRadius], style]}
            color={disabled ? theme.colors.transparentLight : (color || theme.colors.transparentDark)}
            increaseHitSlop={increaseHitSlop}
        >
            <Text
                theme={theme}
                text={text}
            />
        </ContentButton>
    )
})
export const roundButtonSize = screenHeight * 0.06
const RoundContentButton: React.FC<PropsWithChildren<ButtonProps>> = React.memo(({ onPress, onLongPress, longPressDelay, disabled, style, color, increaseHitSlop, theme, children }) => {
    return (
        <ContentButton
            onPress={onPress}
            onLongPress={onLongPress}
            longPressDelay={longPressDelay}
            disabled={disabled || !(onPress || onLongPress)}
            style={[[GlobalStyles.flexCenter, GlobalStyles.padding, { borderRadius: roundButtonSize / 2, width: roundButtonSize, height: roundButtonSize }], style]}
            color={disabled ? theme.colors.transparentLight : (color || theme.colors.transparentDark)}
            increaseHitSlop={increaseHitSlop}
        >
            {children}
        </ContentButton>
    )
})
export const RoundButton: React.FC<TextButtonProps> = React.memo(({ onPress, onLongPress, longPressDelay, disabled, style, color, increaseHitSlop, theme, text }) => {
    return (
        <RoundContentButton
            onPress={onPress}
            onLongPress={onLongPress}
            longPressDelay={longPressDelay}
            disabled={disabled || !(onPress || onLongPress)}
            style={style}
            color={color}
            increaseHitSlop={increaseHitSlop}
            theme={theme}
        >
            <Text
                theme={theme}
                text={text}
            />
        </RoundContentButton>
    )
})
export const BigButton: React.FC<TextButtonProps> = React.memo(({ onPress, onLongPress, longPressDelay, disabled, style, color, increaseHitSlop, theme, text }) => {
    let textColor, textStyle, _text
    if (typeof text === 'string' || Array.isArray(text)) _text = text
    else {
        textColor = text.color
        textStyle = text.style
        _text = text.text
    }

    return (
        <ContentButton
            onPress={onPress}
            onLongPress={onLongPress}
            longPressDelay={longPressDelay}
            disabled={disabled || !(onPress || onLongPress)}
            style={[[GlobalStyles.borderLeftRight, GlobalStyles.padding, theme.styles.borderLight, GlobalStyles.borderRadius], style]}
            color={disabled ? theme.colors.transparentLight : (color || theme.colors.transparentDark)}
            increaseHitSlop={increaseHitSlop}
        >
            <Text
                theme={theme}
                text={{ text: _text, style: [textStyle, GlobalStyles.heading, GlobalStyles.paddingTop], color: textColor }}
            />
        </ContentButton>
    )
})
export const ThemedButton: React.FC<TextButtonProps> = React.memo(({ onPress, onLongPress, longPressDelay, disabled, style, color, increaseHitSlop, theme, text }) => {
    let textColor, textStyle, _text
    if (typeof text === 'string' || Array.isArray(text)) {
        _text = text
    }
    else {
        textColor = text.color
        textStyle = text.style
        _text = text.text
    }

    return (
        <ContentButton
            onPress={onPress}
            onLongPress={onLongPress}
            longPressDelay={longPressDelay}
            disabled={disabled || !(onPress || onLongPress)}
            style={[{ minWidth: screenWidth * 0.3 }, GlobalStyles.padding, style]}
            color={disabled ? theme.colors.transparentLight : (color || theme.colors.layoutColor)}
            increaseHitSlop={increaseHitSlop}
        >
            <Text
                theme={theme}
                text={{
                    text: _text,
                    style: [textStyle, { color: Theme.statics.LIGHT_COLOR } /* static white because the background color is per default the layoutColor which is always a darker color */, GlobalStyles.textAlignCenter],
                    color: textColor
                }}
            />
        </ContentButton>
    )
})

export const FullWidthThemedButton: React.FC<TextButtonProps> = React.memo(({ onPress, onLongPress, longPressDelay, disabled, style, color, increaseHitSlop, theme, text }) => {
    let textColor, textStyle, _text
    if (typeof text === 'string' || Array.isArray(text)) {
        _text = text
    }
    else {
        textColor = text.color
        textStyle = text.style
        _text = text.text
    }



    return (
        <ContentButton
            onPress={onPress}
            onLongPress={onLongPress}
            longPressDelay={longPressDelay}
            disabled={disabled || !(onPress || onLongPress)}
            style={[GlobalStyles.padding, style, GlobalStyles.width100]}
            color={disabled ? theme.colors.transparentLight : (color || theme.colors.layoutColor)}
            increaseHitSlop={increaseHitSlop}
        >
            <Text
                theme={theme}
                text={{
                    text: _text,
                    style: [textStyle, { color: Theme.statics.LIGHT_COLOR } /* static white because the background color is per default the layoutColor which is always a darker color */, GlobalStyles.textAlignCenter],
                    color: textColor
                }}
            />
        </ContentButton>
    )
})






//////////////// Icon buttons ////////////////
interface PresetIconButtonProps extends ButtonProps {
    size?: number,
    iconColor?: color
}

export const CloseButton: React.FC<PresetIconButtonProps> = React.memo(({ onPress, onLongPress, longPressDelay, disabled, style, color, increaseHitSlop, theme, size, iconColor }) => (
    <IconButton icon={{ name: 'close', iconSource: 'MaterialCommunity', color: iconColor, size }} theme={theme} onPress={onPress} onLongPress={onLongPress} longPressDelay={longPressDelay} color={color} style={style} disabled={disabled || !(onPress || onLongPress)} increaseHitSlop={increaseHitSlop} />
))
export const OkButton: React.FC<PresetIconButtonProps> = React.memo(({ onPress, onLongPress, longPressDelay, disabled, style, color, increaseHitSlop, theme, size, iconColor }) => (
    <IconButton icon={{ name: 'checkmark', iconSource: 'IonIcon', color: iconColor, size }} theme={theme} onPress={onPress} onLongPress={onLongPress} longPressDelay={longPressDelay} color={color} style={style} disabled={disabled || !(onPress || onLongPress)} increaseHitSlop={increaseHitSlop} />
))
export const EditButton: React.FC<PresetIconButtonProps> = React.memo(({ onPress, onLongPress, longPressDelay, disabled, style, color, increaseHitSlop, theme, size, iconColor }) => (
    <IconButton icon={{ name: 'pencil-outline', iconSource: 'IonIcon', color: iconColor, size }} theme={theme} onPress={onPress} onLongPress={onLongPress} longPressDelay={longPressDelay} color={color} style={style} disabled={disabled || !(onPress || onLongPress)} increaseHitSlop={increaseHitSlop} />
))
export const DeleteButton: React.FC<PresetIconButtonProps> = React.memo(({ onPress, onLongPress, longPressDelay, disabled, style, color, increaseHitSlop, theme, size, iconColor }) => (
    <IconButton icon={{ name: 'trash-outline', iconSource: 'IonIcon', color: iconColor, size }} theme={theme} onPress={onPress} onLongPress={onLongPress} longPressDelay={longPressDelay} color={color} style={style} disabled={disabled || !(onPress || onLongPress)} increaseHitSlop={increaseHitSlop} />
))
export const CallButton: React.FC<PresetIconButtonProps> = React.memo(({ onPress, onLongPress, longPressDelay, disabled, style, color, increaseHitSlop, theme, size, iconColor }) => (
    <IconButton icon={{ name: 'call-outline', iconSource: 'IonIcon', color: iconColor, size }} theme={theme} onPress={onPress} onLongPress={onLongPress} longPressDelay={longPressDelay} color={color} style={style} disabled={disabled || !(onPress || onLongPress)} increaseHitSlop={increaseHitSlop} />
))
export const VideoCallButton: React.FC<PresetIconButtonProps> = React.memo(({ onPress, onLongPress, longPressDelay, disabled, style, color, increaseHitSlop, theme, size, iconColor }) => (
    <IconButton icon={{ name: 'videocam-outline', iconSource: 'IonIcon', color: iconColor, size }} theme={theme} onPress={onPress} onLongPress={onLongPress} longPressDelay={longPressDelay} color={color} style={style} disabled={disabled || !(onPress || onLongPress)} increaseHitSlop={increaseHitSlop} />
))
export const SyncButton: React.FC<PresetIconButtonProps> = React.memo(({ onPress, onLongPress, longPressDelay, disabled, style, color, increaseHitSlop, theme, size, iconColor }) => (
    <IconButton icon={{ name: 'sync-circle', iconSource: 'IonIcon', color: iconColor, size }} theme={theme} onPress={onPress} longPressDelay={longPressDelay} color={color} style={style} disabled={disabled || !(onPress || onLongPress)} increaseHitSlop={increaseHitSlop} />
))
export const SearchButton: React.FC<PresetIconButtonProps> = React.memo(({ onPress, onLongPress, longPressDelay, disabled, style, color, increaseHitSlop, theme, size, iconColor }) => (
    <Animated.View entering={FadeInRight} exiting={FadeOutRight.duration(10)}>
        <IconButton icon={{ iconSource: 'MaterialCommunity', name: 'magnify', color: iconColor, size: size }} theme={theme} onPress={onPress} onLongPress={onLongPress} longPressDelay={longPressDelay} color={color} style={style} disabled={disabled || !(onPress || onLongPress)} increaseHitSlop={increaseHitSlop} />
    </Animated.View>
))
export const SyncButtonWithLabel: React.FC<PresetIconButtonProps> = React.memo(({ onPress, onLongPress, longPressDelay, disabled, style, color, increaseHitSlop, theme, size, iconColor }) => {
    const $sync = useTranslation().t('global.sync.$sync')
    const _disabled = disabled || !(onPress || onLongPress)
    return (
        <ContentButton style={[GlobalStyles.flexStart, style]} onPress={onPress} onLongPress={onLongPress} disabled={_disabled}>
            <Text theme={theme} text={{ text: $sync, style: GlobalStyles.marginRight }} />
            <SyncButton onPress={onPress} onLongPress={onLongPress} disabled={_disabled} color={color} increaseHitSlop={increaseHitSlop} theme={theme} longPressDelay={longPressDelay} size={size} iconColor={iconColor} />
        </ContentButton>
    )
})



export const LanguageButton: React.FC<PresetIconButtonProps> = React.memo(({ onPress, onLongPress, longPressDelay, disabled, style, color, increaseHitSlop, theme, size, iconColor }) => (
    <IconButton icon={{ name: "language", iconSource: "IonIcon", size: size || DEFAULT_BIG_ICON_SIZE, color: color || iconColor }} theme={theme} longPressDelay={longPressDelay} onPress={() => StateAPI.setDisplayLanguageSelection(true)} color={color} style={style} disabled={disabled} increaseHitSlop={increaseHitSlop} />
))
interface CopyToClipboardButtonProps extends PresetIconButtonProps {
    text: highlightableText,
    clipboard?: string
}
export const CopyToClipboardButton: React.FC<CopyToClipboardButtonProps> = React.memo(({ disabled, style, color, increaseHitSlop, theme, size, iconColor, text, clipboard }) => {
    const [copied, setCopied] = useState(false)
    const coordinated = useCoordinatedCallbacks([
        async () => {
            StateAPI.executeAnimationFunctions([
                async () => {
                    setCopied(true)
                    await DeviceAPI.copyToClipboard((clipboard || typeof text !== 'string') ? (clipboard || 'invalid props, "clipboard" prop required if text is highlightedText') : text)
                },
                () => setCopied(false)
            ])
        }
    ])
    const _onPress = coordinated.coordinatedCallbacks[0]

    return (
        <>
            {copied ? (
                <FadingOkIcon theme={theme} />
            ) : (
                <Animated.View entering={FadeIn} exiting={FadeOut}>
                    <IconButton icon={{ name: 'copy-outline', iconSource: 'IonIcon', color: iconColor, size }} theme={theme} onPress={_onPress} color={color} style={style} disabled={disabled} increaseHitSlop={increaseHitSlop} />
                </Animated.View>
            )}
        </>
    )
})
export const CopyToClipboardButtonWithLabel: React.FC<CopyToClipboardButtonProps> = React.memo(({ disabled, style, color, increaseHitSlop, theme, size, iconColor, text, clipboard }) => {
    const [copied, setCopied] = useState(false)
    const coordinated = useCoordinatedCallbacks([
        async () => {
            StateAPI.executeAnimationFunctions([
                async () => {
                    setCopied(true)
                    await DeviceAPI.copyToClipboard((clipboard || typeof text !== 'string') ? (clipboard || 'invalid props, "clipboard" prop required if text is highlightedText') : text)
                },
                () => setCopied(false)
            ])
        }
    ])
    const _onPress = coordinated.coordinatedCallbacks[0]


    return (
        <ContentButton style={[GlobalStyles.flex]} onPress={_onPress} increaseHitSlop={increaseHitSlop}>
            <Text theme={theme} text={text} />
            <>
                {copied ? (
                    <FadingOkIcon theme={theme} />
                ) : (
                    <Animated.View entering={FadeIn} exiting={FadeOut}>
                        <IconButton icon={{ name: 'copy-outline', iconSource: 'IonIcon', color: iconColor, size }} theme={theme} onPress={_onPress} color={color} style={style} disabled={disabled} increaseHitSlop={increaseHitSlop} />
                    </Animated.View>
                )}
            </>
        </ContentButton>
    )
})




interface ExpandableButtonProps extends PresetIconButtonProps {
    expanded: boolean
}
export const ExpandableButton: React.FC<ExpandableButtonProps> = React.memo(({ onPress, onLongPress, longPressDelay, disabled, style, color: _color, increaseHitSlop, theme, size, iconColor, expanded }) => {
    return (
        expanded ? (
            <IconButton icon={{ name: 'caret-up', iconSource: 'IonIcon', color: iconColor, size }} theme={theme} onPress={onPress} onLongPress={onLongPress} longPressDelay={longPressDelay} color={_color} style={style} disabled={disabled || !(onPress || onLongPress)} increaseHitSlop={true} />
        ) : (
            <IconButton icon={{ name: 'caret-down', iconSource: 'IonIcon', color: iconColor, size }} theme={theme} onPress={onPress} onLongPress={onLongPress} longPressDelay={longPressDelay} color={_color} style={style} disabled={disabled || !(onPress || onLongPress)} increaseHitSlop={true} />
        )
    )
})
interface ExpandableButtonWithLabelProps extends ExpandableButtonProps, TextProps {
    labelStyle?: ViewStyle
}
export const ExpandableButtonWithLabel: React.FC<ExpandableButtonWithLabelProps> = React.memo(({ onPress, onLongPress, longPressDelay, disabled, style, color, increaseHitSlop, theme, size, iconColor, expanded, text, labelStyle }) => {
    return (
        <ContentButton style={[GlobalStyles.flexBetween, GlobalStyles.horizontalPadding, GlobalStyles.borderLeftRight, theme.styles.backgroundTransparentDark, theme.styles.borderLight, GlobalStyles.borderRadius, labelStyle]} onPress={onPress} increaseHitSlop={increaseHitSlop}>
            <Text theme={theme} text={text} />
            <ExpandableButton onPress={onPress} onLongPress={onLongPress} longPressDelay={longPressDelay} theme={theme} expanded={expanded} color={color} size={size} style={[GlobalStyles.marginLeft, style]} disabled={disabled || !(onPress || onLongPress)} iconColor={iconColor} />
        </ContentButton>
    )
})

interface ToggleButtonProps extends TextButtonProps, PresetIconButtonProps {
    invert?: true
    iconSize?: number
    value: boolean
    onPress: (value: boolean) => void
}
export const ToggleButton: React.FC<ToggleButtonProps> = React.memo(({ onPress, onLongPress, longPressDelay, disabled, style, color, increaseHitSlop, theme, size, iconColor = theme.colors.accentColor, text, invert, iconSize, value }) => {

    return (
        <TextIconButton
            onPress={() => onPress(!value)}
            onLongPress={onLongPress}
            longPressDelay={longPressDelay}
            disabled={disabled || !(onPress || onLongPress)}
            invert={invert}
            theme={theme}
            increaseHitSlop={increaseHitSlop}
            text={text}
            icon={{ name: value ? 'checkbox-outline' : 'square-outline', iconSource: 'IonIcon', color: iconColor, size: iconSize }}
            color={color}
            style={[GlobalStyles.flexStart, style]}
        />

    )
})






interface IconButtonProps extends ButtonProps, IconProps {
}
export const IconButton: React.FC<IconButtonProps> = React.memo(({ onPress, onLongPress, longPressDelay, disabled, style, color: _color, increaseHitSlop, theme, icon }) => {
    const { name, iconSource } = icon
    const color = disabled ? theme.colors.transparentLight : (icon.color || theme.colors.accentColor)

    return (
        <ContentButton
            onPress={onPress}
            onLongPress={onLongPress}
            longPressDelay={longPressDelay}
            disabled={disabled || !(onPress || onLongPress)}
            color={_color}
            style={[style]}
            increaseHitSlop={increaseHitSlop}
        >
            <Icon iconSource={iconSource} name={name} size={icon.size} color={color} />
        </ContentButton>
    )
})
interface IconTextButton extends TextIconProps, ButtonProps {
    invert?: true
}
export const RoundIconButton: React.FC<IconButtonProps> = React.memo(({ onPress, onLongPress, longPressDelay, disabled, style, color: _color, increaseHitSlop, theme, icon }) => {
    const { name, iconSource } = icon
    const color = disabled ? theme.colors.transparentLight : (icon.color || theme.colors.accentColor)

    return (
        <RoundContentButton
            onPress={onPress}
            onLongPress={onLongPress}
            longPressDelay={longPressDelay}
            disabled={disabled || !(onPress || onLongPress)}
            color={_color}
            style={style}
            increaseHitSlop={increaseHitSlop}
            theme={theme}
        >
            <Icon iconSource={iconSource} name={name} size={icon.size} color={color} />
        </RoundContentButton>
    )
})
export const TextIconButton: React.FC<IconTextButton> = React.memo(({ onPress, onLongPress, longPressDelay, disabled, style, color: _color, increaseHitSlop, theme, text, icon, invert }) => {
    const color = disabled ? theme.colors.transparentLight : (icon.color || theme.colors.accentColor)

    return (
        <ContentButton
            onPress={onPress}
            onLongPress={onLongPress}
            longPressDelay={longPressDelay}
            disabled={disabled || !(onPress || onLongPress)}
            color={_color}
            style={style}
            increaseHitSlop={increaseHitSlop}
        >
            <IconText theme={theme} text={text} icon={{ ...icon, color }} invert={invert} />
        </ContentButton>
    )
})
