// Mock Implementation
import Clipboard from '@react-native-community/clipboard'
import { Alert, Appearance, Linking, NativeEventSubscription, PermissionsAndroid, Platform, NativeModules, EmitterSubscription } from 'react-native'
import { OK_BUTTON_TIMEOUT } from '../../env'
import { getTimeZone, getLocales, uses24HourClock } from 'react-native-localize'
import Device from './Device'

export type handleLink = (link: string) => void | Promise<void>
export type handleDeviceThemeChange = (deviceUsesDarkMode: boolean) => void

export default abstract class DeviceAPI extends Device {
    static getOS() {
        return Platform.OS
    }
    static getTimezone() {
        return getTimeZone()
    }
    static readonly appStartTime = Date.now()
    static getLocale() {
        return getLocales()[0]?.languageCode || 'en'
    }
    static uses24HourClock() {
        return uses24HourClock()
    }
    static addHardwareBackPressListener(f: () => undefined | null | boolean): undefined | {remove: () => void} {
        return undefined
    }


    private static linkListener: EmitterSubscription
    private static startLinkListener = async (handleLink: handleLink) => {
        if (!this.linkListener) {
            const openingLink = await Linking.getInitialURL();

            if (openingLink) { // link was used to open app
                await handleLink(openingLink)
            }

            this.linkListener = Linking.addEventListener('url', async ({ url }) => await handleLink(url))
        }
    }

    static stopLinkListener = async () => {
        if (this.linkListener) this.linkListener.remove()
    }


    private static currentlyUsesDarkMode: boolean = true
    static deviceUsesDarkMode() {
        return this.currentlyUsesDarkMode
    }
    private static deviceThemeListener: NativeEventSubscription | undefined
    private static startDeviceThemeListener = (handleDeviceThemeChange: handleDeviceThemeChange) => {
        if (!this.deviceThemeListener) {
            const mode = (Appearance.getColorScheme() || 'dark') === 'dark'
            const deviceThemeListener = Appearance.addChangeListener((deviceTheme) => {
                const mode = (deviceTheme.colorScheme || 'dark') === 'dark'
                this.currentlyUsesDarkMode = mode
                handleDeviceThemeChange(mode)
            })
            handleDeviceThemeChange(mode)
            this.currentlyUsesDarkMode = mode

            this.deviceThemeListener = deviceThemeListener
        }
    }
    static stopDeviceThemeListener = () => {
        if (this.deviceThemeListener) this.deviceThemeListener.remove()
    }

    // Tries to open a url/link using the operating system handling system
    static openLink = async (url: string) => {
        try {
            const canOpen = await Linking.canOpenURL(url)
            if (canOpen) {
                await Linking.openURL(url)
                return true
            }
            else return false
        } catch (error) {
            this.logAPI.error('openLink', error)
            return false
        }
    }
    static async copyToClipboard(string: string) {
        console.log("copying to clipboard")
        Clipboard.setString(string)
        await new Promise<void>(resolve => setTimeout(resolve, OK_BUTTON_TIMEOUT))
    }


    static initialize = async (handleDeviceThemeChange: handleDeviceThemeChange, handleLink: handleLink) => {
        this.startDeviceThemeListener(handleDeviceThemeChange)
        this.startLinkListener(handleLink)
    }
    static destroy = () => {
        this.stopDeviceThemeListener()
        this.stopLinkListener()
    }

    protected static logAPI = super.logAPI.extend('Device.api')
}