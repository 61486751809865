import React, { useMemo } from "react"
// @ts-ignore
// import Loading from '../../assets/videos/loading.mp4'


import SvgAnimationAPI from "../../../apis/ui/SvgAnimationAPI"
import { ThemeProps } from "../../../styles/Theme"



const LoadingAnimationOverlay: React.FC<ThemeProps> = ({ theme }) => {
    const loadingAnimation = useMemo(() => SvgAnimationAPI.getLoadingAnimationJSX(), [theme])
    const { LoadingAnimation, onMessage } = loadingAnimation
    // console.log('Loading - Animation', LoadingAnimationOverlay)
    return <LoadingAnimation onMessage={onMessage} />
}


export default React.memo(LoadingAnimationOverlay)