import React, { useMemo, useRef } from "react";
import { View } from "react-native";
import SvgAnimationAPI, { playerRef, svgProps } from "../../../apis/ui/SvgAnimationAPI";
import Theme from "../../../styles/Theme";
import { WebViewProps } from "react-native-webview";

interface Props extends WebViewProps {
    theme: Theme
    width?: number
}

const ThickLogoAnimation: React.FC<Props> = (props) => {
    const ThickLogoAnimation = useMemo(() => SvgAnimationAPI.getThickLogoAnimationJSX().ThickLogoAnimation, [props.theme])

    return <ThickLogoAnimation {...props} onMessage={(event: any) => console.log(event.nativeEvent.data)} />
}

export default React.memo(ThickLogoAnimation)