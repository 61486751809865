import React from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import AntDesignIcon from "react-native-vector-icons/AntDesign";
import EntypoIcon from "react-native-vector-icons/Entypo";
import FontAwesomeIcon from "react-native-vector-icons/FontAwesome";
import FontAwesome5Icon from "react-native-vector-icons/FontAwesome5";
import IonIcon from 'react-native-vector-icons/Ionicons';
import MaterialCommunityIcon from 'react-native-vector-icons/MaterialCommunityIcons';
import MaterialDesignIcon from "react-native-vector-icons/MaterialIcons";
import ThickLogoAnimation from "./Animations/ThickLogoAnimation";
import { BigHeading, Heading, SmallText, Text, TextProps, VerySmallText } from "./Text";
import { DEFAULT_VERY_BIG_FONT_SIZE, DEFAULT_BIG_FONT_SIZE, screenWidth, DEFAULT_ICON_SIZE, DEFAULT_BIG_ICON_SIZE, DEFAULT_SMALL_ICON_SIZE, DEFAULT_VERY_SMALL_ICON_SIZE, DEFAULT_VERY_BIG_ICON_SIZE } from "../../env";
import { color } from "../../apis/storage/MemberStorage";
import Theme, { ThemeProps } from "../../styles/Theme";
import GlobalStyles from "../../styles/global-styles";
import Animated, { FadeIn, FadeOut } from "react-native-reanimated";

export type iconSource = 'FontAwesome' | 'FontAwesome5' | 'IonIcon' | 'MaterialDesign' | 'MaterialCommunity' | 'AntDesign' | 'Entypo'
export interface icon {
    iconSource: iconSource,
    name: string,
    size?: number,
    color?: color
}


const Icon: React.FC<icon> = ({ iconSource, name, size = DEFAULT_ICON_SIZE, color }) => {
    switch (iconSource) {
        case 'FontAwesome':
            return <FontAwesomeIcon name={name} size={size} color={color} />
        case 'IonIcon':
            return <IonIcon name={name} size={size} color={color} />
        case 'MaterialDesign':
            return <MaterialDesignIcon name={name} size={size} color={color} />
        case 'MaterialCommunity':
            return <MaterialCommunityIcon name={name} size={size} color={color} />
        case 'AntDesign':
            return <AntDesignIcon name={name} size={size} color={color} />
        case 'Entypo':
            return <EntypoIcon name={name} size={size} color={color} />
        default:
            return <FontAwesome5Icon name={name} size={size} color={color} />
    }
}


export interface IconProps { icon: icon }

export interface ContentIconProps extends IconProps {
    style?: StyleProp<ViewStyle>
    theme: Theme
    invert?: true
}
export interface TextIconProps extends TextProps, ContentIconProps { }



const ContentIcon = React.memo(({ children, theme, icon, invert, style }: React.PropsWithChildren<ContentIconProps>) => {
    const { name, iconSource, size, color } = icon

    return (
        <View style={[GlobalStyles.flexStart, style]}>
            {!invert && (
                <View style={GlobalStyles.marginRight}>
                    <Icon iconSource={iconSource} name={name} size={size} color={color || theme.colors.accentColor} />
                </View>
            )}
            {children}
            {invert && (
                <View style={GlobalStyles.marginLeft}>
                    <Icon iconSource={iconSource} name={name} size={size} color={color || theme.colors.accentColor} />
                </View>
            )}
        </View>
    )
})

export const IconText = React.memo(({ theme, text, icon, invert, style }: TextIconProps) => (
    <ContentIcon theme={theme} icon={icon} invert={invert} style={style}>
        <Text theme={theme} text={text} />
    </ContentIcon>
))
export const SmallIconText = React.memo(({ theme, text, icon, invert, style }: TextIconProps) => (
    <ContentIcon theme={theme} icon={{ ...icon, size: icon.size || DEFAULT_SMALL_ICON_SIZE }} invert={invert} style={style}>
        <SmallText theme={theme} text={text} />
    </ContentIcon>
))
export const VerySmallIconText = React.memo(({ theme, text, icon, invert, style }: TextIconProps) => (
    <ContentIcon theme={theme} icon={{ ...icon, size: icon.size || DEFAULT_VERY_SMALL_ICON_SIZE }} invert={invert} style={style}>
        <VerySmallText theme={theme} text={text} />
    </ContentIcon>
))
export const IconHeading = React.memo(({ theme, text, icon, invert, style }: TextIconProps) => (
    <ContentIcon theme={theme} icon={{ ...icon, size: icon.size || DEFAULT_BIG_ICON_SIZE }} invert={invert} style={style}>
        <Heading theme={theme} text={text} />
    </ContentIcon>
))
export const BigIconHeading = React.memo(({ theme, text, icon, invert, style }: TextIconProps) => (
    <ContentIcon theme={theme} icon={{ ...icon, size: icon.size || DEFAULT_VERY_BIG_ICON_SIZE }} invert={invert} style={style}>
        <BigHeading theme={theme} text={text} />
    </ContentIcon>
))

interface LogoIconHeadingProps {
    theme: Theme
    title: string
}
export const LogoIconHeading = ({ theme, title }: LogoIconHeadingProps) => {
    return (
        <View style={GlobalStyles.flexStart}>
            <ThickLogoAnimation theme={theme} width={DEFAULT_BIG_ICON_SIZE} />
            <Heading theme={theme} text={title} />
        </View>
    )
}


export const FadingOkIcon: React.FC<ThemeProps> = ({ theme }) => (
    <Animated.View entering={FadeIn} exiting={FadeOut}>
        <Icon iconSource="IonIcon" name='checkmark' color={theme.colors.accentColor} />
    </Animated.View>
)




export default React.memo(Icon)