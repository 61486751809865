/////// Times ///////
export const oneYearInMs = 31556952000
export const oneMonthInMs = 2.6298e+9
export const oneDayInMs = 86400000
export const halfHourInMs = 1.8e+6
export const oneHourInMs = 3600000
export const oneSecondInMs = 1000

/////// Different configuration consts /////// 
export const TIMEOUT_REGISTRATION_POLLING = 5000 //5 s
export const ITERATIONS_REGISTRATION_POLLING = 120

export const maxContractDuration = 50
export const minContractDuration = 1

/////// Payment Configurations ///////
export const PRICES = {
  Member: 3.99,
  "Leader": 17.99,
  Visionary: 299.99
}
