import React, { useCallback, useMemo, useState } from "react"
import { LayoutChangeEvent, StyleSheet, View } from "react-native"
import { Gesture, GestureDetector } from "react-native-gesture-handler"
import Animated, { FadeInDown, runOnJS, useAnimatedStyle, useSharedValue, withSpring } from "react-native-reanimated"
import { INCREASED_HIT_SLOP, screenHeight, screenWidth } from "../../env"
import Theme from "../../styles/Theme"
import GlobalStyles from "../../styles/global-styles"
import { ContentButton } from "./Buttons"
import { Text } from "./Text"



type SliderPosition = {
    key: string
    value: any
}

interface Props {
    theme: Theme
    positions: (SliderPosition | string | number)[]
    initialValue: any
    onChange: (value: any) => void
    relativeSliderWidth?: 0.5 | 0.55 | 0.6 | 0.65 | 0.7 | 0.75 | 0.8 | 0.85 | 0.9 | 0.95
}


const sliderDragButtonWidth = screenWidth * 0.0075
const currentPositionIndicatorWidth = screenWidth * 0.002
const sliderWithSpringConfig = { mass: 0.75 }
const defaultSliderWidth = 0.8
const internalSliderHeight = screenHeight * 0.03
const halfInternalSliderHeight = internalSliderHeight / 2
export const sliderHeight = 40 + GlobalStyles.verticalMargin.marginVertical * 2

const Slider: React.FC<Props> = ({ theme, positions: inputPositions, initialValue, onChange, relativeSliderWidth = defaultSliderWidth }) => {
    const positions: SliderPosition[] = useMemo(() => {
        if (typeof inputPositions[0] === 'string' || typeof inputPositions[0] === 'number') {
            const positions = inputPositions.map((p, i) => ({ key: p, value: p } as SliderPosition))
            return positions
        }
        else return inputPositions as SliderPosition[]
    }, [inputPositions])
    const initialPosition = (() => {
        const position = positions.findIndex(p => p.value === initialValue)
        if (position === -1) {
            console.error(`Error in Slider.tsx initialValue ${initialValue} doesnt align with positions ${JSON.stringify(positions)} defaulting to 0`)
            return 0
        }
        else return position
    })()


    const x = useSharedValue(0)
    const pressed = useSharedValue(false)
    const [maxSliderWidth, setMaxSliderWidth] = useState(0)
    const onLayout = useCallback((e: LayoutChangeEvent) => {
        setMaxSliderWidth(e.nativeEvent.layout.width)
    }, [])

    const xPositions = useMemo(() => {
        const _positions: number[] = []

        for (let i = 0; i < positions.length; i++) {
            // console.log('Setting _position', i, 'to', maxSliderWidth, i, positions.length - 1)
            _positions[i] = i !== 0 ? maxSliderWidth * i / (positions.length - 1) : i
        }
        // console.log('Available positions', _positions)
        x.value = withSpring(_positions[initialPosition]! - sliderDragButtonWidth / 2)
        pressed.value = false
        return _positions
    }, [maxSliderWidth, positions, initialPosition])
    // const averageXPositionDistance = xPositions[1]! - xPositions[0]!



    const colors = theme.colors
    const animatedSlider = useAnimatedStyle(() => {
        return {
            backgroundColor: colors.accentColor /* pressed.value ? colors.accentColor : colors.layoutColor */,
            transform: [{ scaleY: withSpring(pressed.value ? 1.5 : 1) }, { translateX: x.value }],
        };
    });


    const onPress = (i: number) => {
        x.value = withSpring(xPositions[i]! - sliderDragButtonWidth / 2, sliderWithSpringConfig)
        onChange(positions[i]!.value)
    }

    const startX = useSharedValue(0)
    const pan = Gesture.Pan()
        .onStart(e => {
            pressed.value = true;
            startX.value = x.value;
        })
        .onUpdate(e => {
            // @ts-ignore
            x.value = startX.value + e.translationX;
            /*let closest = {
                index: 0,
                diff: Math.abs(xPositions[0] - x.value)
            };
            for (let i = 1; i < positions.length; i++) {
                const diff = Math.abs(xPositions[i] - x.value);
                if (diff < closest.diff) {
                    closest = {
                        index: i,
                        diff: diff
                    };
                }
            }
            x.value = withSpring(xPositions[closest.index] - sliderDragButtonWidth / 2);*/
        })
        .onEnd(e => {
            pressed.value = false;
            let index = 0
            for (let i = 0; i < positions.length; i++) {
                const nextIndex = i + 1
                const iValue = xPositions[i]!
                const nextIndexValue = xPositions[nextIndex]!

                // console.log('nextIndex/iValue/nextIndexValue/x.value', nextIndex, iValue, nextIndexValue, x.value)
                if ((iValue <= x.value) && nextIndex < positions.length ? nextIndexValue > x.value : true) { // x.value is between i and nextIndex
                    const iDiff = x.value - iValue
                    const nextIndexDiff = nextIndexValue - x.value
                    if (iDiff < nextIndexDiff) index = i // distance from x.value to i is smaller --> index = i
                    else if (nextIndex > positions.length - 1) index = i
                    else index = nextIndex // distance from nextIndex to x.value is smaller --> index = nextIndex
                    break;
                }
            }
            // console.log('onGestureEnd', x.value, xPositions, index)
            x.value = withSpring(xPositions[index]! - sliderDragButtonWidth / 2, sliderWithSpringConfig)
            // console.log('onGestureEnd', x.value, xPositions[index] - sliderDragButtonWidth / 2)
            runOnJS(onChange)(positions[index]!.value)
        })
        .hitSlop(INCREASED_HIT_SLOP)

    return (
        <View style={[GlobalStyles.paddingBottom, GlobalStyles.width100, GlobalStyles.horizontalPadding]}>
            <View style={[GlobalStyles.flexCenter]}>
                <View style={[{ width: `${relativeSliderWidth * 100}%` }]} onLayout={onLayout}>
                    <GestureDetector gesture={pan}>
                        <Animated.View style={[{ width: sliderDragButtonWidth, height: internalSliderHeight * 1.2, borderRadius: halfInternalSliderHeight }, animatedSlider, theme.styles.backgroundAccentColor]} />
                    </GestureDetector>
                    <View style={{ borderTopColor: colors.light, borderTopWidth: StyleSheet.hairlineWidth, marginTop: -halfInternalSliderHeight * 1.2, zIndex: -1 }} />
                    <View style={[GlobalStyles.flexBetween, { marginTop: -halfInternalSliderHeight, zIndex: -1 }]}>
                        {positions.map((position, i) => (
                            <ContentButton
                                key={`position-${position.key}`}
                                onPress={() => onPress(i)}
                                increaseHitSlop
                                style={[{ width: currentPositionIndicatorWidth, height: internalSliderHeight }, theme.styles.backgroundAccentColor]}
                            />
                        ))}
                    </View>
                </View>
                <View style={[GlobalStyles.width100, GlobalStyles.flexBetween, { alignItems: 'flex-start', marginTop: halfInternalSliderHeight / 2, zIndex: -1 }]}>
                    {positions.map((position, i) => <PositionIndicator key={`position-indicator-${position.key}`} theme={theme} i={i} position={position} onPress={onPress} />)}
                </View>
            </View>
        </View>
    )
}



interface PositionIndicatorProps {
    theme: Theme
    i: number
    position: SliderPosition
    onPress: (i: number) => void
}
const PositionIndicator: React.FC<PositionIndicatorProps> = React.memo(({ theme, i, position, onPress }) => (
    <Animated.View entering={FadeInDown} style={GlobalStyles.horizontalMargin}>
        <ContentButton onPress={() => onPress(i)}>
            <Text theme={theme} text={{ text: position.key, style: [theme.styles.textLightAccent, GlobalStyles.smallText] }} />
        </ContentButton>
    </Animated.View>
))




export default React.memo(Slider)