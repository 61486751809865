import React, { Ref, useCallback, useEffect, useMemo, useState } from "react";
import { Freeze } from "react-freeze";
import { LayoutChangeEvent, StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import Animated, { FadeIn, SharedValue, StretchOutX, WithSpringConfig, runOnJS, useAnimatedGestureHandler, useAnimatedStyle, useSharedValue, withSpring } from "react-native-reanimated";
import { DEFAULT_SMALL_ICON_SIZE, screenHeight, screenWidth } from "../../env";
import StateAPI, { setSwipePosition, swipePosition } from "../../apis/ui/StateAPI";
import GlobalStyles from "../../styles/global-styles";
import { GestureHandlerContentButton } from "./Buttons";
import { Gesture, GestureDetector, PanGesture, PinchGesture } from "react-native-gesture-handler";
import OverlayContainer from "./Overlays/OverlayContainer";
import Icon from "./Icon";
import Theme, { ThemeProps } from "../../styles/Theme";
import SwipeableIndicatorOverlay, { ThemePropsWithSwipeableIndicator } from "./Overlays/SwipeableIndicatorOverlay";

type onPress = (swipePosition: number, isNewSwipePosition: boolean) => void
interface Props {
    swipeableIndicator?: { theme: Theme } | false
    simulatneousGestureHandlers?: PinchGesture

    x?: SharedValue<number>
    swipePosition: swipePosition
    setSwipePosition: setSwipePosition
    content: JSX.Element[],
    width?: number,

    // Settings
    swipeSensitivity?: 0.01 | 0.1 | 0.2 | 0.3 | 0.4 | 0.5 | 0.6 | 0.7 // how much area you have to cover to swipe to the next item
    displayAllItems?: true
    disableOptimizations?: true
    animationConfig?: WithSpringConfig
    // Callbacks
    onOverswipeLeft?: () => void
    onOverswipeRight?: () => void
    onPress?: onPress

    // Style
    style?: StyleProp<ViewStyle>
    contentStyle?: StyleProp<ViewStyle>
}

const defaultWithSpringAnimationConfig: WithSpringConfig = { damping: 10, stiffness: 50 }
const defaultSwipeSensitivity = 0.1 // 0.28


const SwipeContainer: React.FC<Props> = ({
    swipeableIndicator,
    simulatneousGestureHandlers,

    x: externalX,
    swipePosition,
    setSwipePosition,
    content,
    width: presetContainerWidth,

    swipeSensitivity,
    displayAllItems,
    disableOptimizations,
    animationConfig,

    onOverswipeLeft,
    onOverswipeRight,
    onPress,

    style,
    contentStyle
}) => {
    const [containerWidth, setContainerWidth] = useState(presetContainerWidth || 0)
    const x = useSharedValue(0)
    const positionalConfig = useMemo(() => {
        const _withSpringAnimation = animationConfig || defaultWithSpringAnimationConfig
        const _swipeSensitivity = swipeSensitivity || defaultSwipeSensitivity
        const _disableOptimizations = displayAllItems || disableOptimizations
        const _onOverswipeLeft = onOverswipeLeft ? onOverswipeLeft : () => { }
        const _onOverswipeRight = onOverswipeRight ? onOverswipeRight : () => { }
        const _onPress = ((swipePosition, isNewSwipePosition) => {
            if (onPress) onPress(swipePosition, isNewSwipePosition)
        }) as onPress


        // Positional
        const positions = content.map((content, index) => ({ content, index }))
        const middlePosition = Math.floor((positions.length) / 2)
        const lastPositionIndex = positions.length - 1
        const positionOffset = displayAllItems ? (containerWidth / positions.length) : containerWidth
        // const swipePosition = useMemo(() => swipePosition < content.length ? swipePosition : Math.floor((lastPositionIndex) / 2), [swipePosition])
        const setX = (_x: number) => {
            'worklet'
            x.value = _x
            if (externalX) externalX.value = _x
        }
        const setXAnimated = (_x: number) => {
            'worklet'
            x.value = withSpring(_x, _withSpringAnimation)
            if (externalX) externalX.value = withSpring(_x, _withSpringAnimation)
        }

        // Creates [100, 50, 0, -50, -100] values
        const xPositions = positions.map((_, i) => -1 * positionOffset * (i - middlePosition))


        return {
            _swipeSensitivity,
            _disableOptimizations,
            _onOverswipeLeft,
            _onOverswipeRight,
            _onPress,
            positions,
            middlePosition,
            lastPositionIndex,
            positionOffset,
            setX,
            setXAnimated,
            xPositions,
        }
    }, [externalX, content, swipeSensitivity, displayAllItems, disableOptimizations, animationConfig, containerWidth, onOverswipeLeft, onOverswipeRight, onPress])
    const {
        _swipeSensitivity,
        _disableOptimizations,
        _onOverswipeLeft,
        _onOverswipeRight,
        _onPress,
        positions,
        middlePosition,
        lastPositionIndex,
        positionOffset,
        setX,
        setXAnimated,
        xPositions,
    } = positionalConfig


    const onLayout = useCallback((e: LayoutChangeEvent) => {
        if (containerWidth == 0) setContainerWidth(e.nativeEvent.layout.width)
    }, [containerWidth])


    // Optimizations
    const timeout = useSharedValue<undefined | NodeJS.Timeout>(undefined)
    const [displayAll, setDisplayAll] = useState(false)
    const optimizationConfig = useMemo(() => {
        const displayAllOnStart = () => {
            if (timeout.value) clearTimeout(timeout.value)
            if (!displayAll && !_disableOptimizations) setDisplayAll(true)
        }
        const unsetDisplayAll = () => {
            if (timeout.value) clearTimeout(timeout.value)
            timeout.value = setTimeout(() => displayAll && !_disableOptimizations && setDisplayAll(false), 500)
        }
        const setSwipePositionAndUnsetDisplayAll = (index: number) => {
            setSwipePosition(index)
            unsetDisplayAll()
        }
        return { displayAllOnStart, unsetDisplayAll, setSwipePositionAndUnsetDisplayAll }
    }, [timeout, displayAll, positionalConfig, setSwipePosition])
    const { displayAllOnStart, unsetDisplayAll, setSwipePositionAndUnsetDisplayAll } = optimizationConfig






    // On setSwipePosition
    useEffect(() => {
        if (x.value !== xPositions[swipePosition]) {
            displayAllOnStart()
            setXAnimated(xPositions[swipePosition]!)
            StateAPI.dismissKeyboard()
            unsetDisplayAll()
            _onPress(swipePosition, true)
        }
    }, [swipePosition, positionalConfig, optimizationConfig])

    // Get onPressSwipeIten function
    const getOnPressSwipeItemHandler = useCallback((index: number) => {
        if (onPress) {
            return () => {
                const isNewIndex = index !== swipePosition
                console.log({ onPressSwipeContainer: isNewIndex, index })
                if (isNewIndex) {
                    displayAllOnStart()
                    setXAnimated(xPositions[index]!)
                    setSwipePositionAndUnsetDisplayAll(index)
                }
                _onPress(index, isNewIndex)
            }
        }
    }, [swipePosition, positionalConfig, optimizationConfig])


    const animation = useAnimatedStyle(() => ({ transform: [{ translateX: x.value }] }));

    const startX = useSharedValue(0)
    const pan = Gesture.Pan()
        .onStart(event => {
            startX.value = x.value;
        })
        .onUpdate(event => {
            const { translationX, translationY } = event
            if (translationX > screenWidth * 0.025 && !displayAll) {
                // console.log({ onActiveSwipeContainer: translationX > screenWidth * 0.025 && !displayAll })
                // console.log({ translationX, required: screenWidth * 0.025, displayAll })
                runOnJS(displayAllOnStart)()
            }
            setX(startX.value + translationX)
        })
        .onEnd(event => {
            let index = 0
            const overswipeSensitivity = Math.floor(_swipeSensitivity * screenWidth * 2)
            const overswipeLeft = x.value > xPositions[0]!
            const inOverswipeLeftNoActionArea = overswipeLeft && !(x.value > (xPositions[0]! + overswipeSensitivity))
            const overswipeRight = x.value < xPositions[lastPositionIndex]!
            const inOverswipeRightNoActionArea = overswipeRight && !(x.value < (xPositions[lastPositionIndex]! - overswipeSensitivity))

            // console.log({ swipeSensitivity: 1 - _swipeSensitivity, swipePosition })
            // console.log({value: x.value, firstCase: x.value > xPositions[0]!, secondCase: x.value < xPositions[lastPositionIndex]!})

            if (overswipeLeft) {
                index = 0
            }
            else if (overswipeRight) {
                index = lastPositionIndex
            }
            else {
                if (x.value < 0) {
                    const arr = xPositions.slice(middlePosition)

                    for (let i = 0; i < arr.length; i++) {
                        const nextIndex = i + 1
                        const indexValue = arr[i]!
                        const nextIndexValue = arr[nextIndex]
                        const realIndex = i + middlePosition

                        // console.log({ index: realIndex, indexValue, nextIndexValue, currentIndexIsBest: nextIndexValue ? x.value > nextIndexValue * (1 - _swipeSensitivity) : undefined })

                        if (!nextIndexValue) { // end reached
                            if (realIndex === swipePosition) { // else we have difficulties swiping when reaching end and wanting to get away from it
                                if (x.value > indexValue * _swipeSensitivity) index = realIndex
                                else index = realIndex - 1
                            }
                            else index = realIndex
                        }
                        else {
                            if (realIndex === swipePosition) {
                                if (x.value > nextIndexValue * _swipeSensitivity) { // current index is best because its diff is smaller than nextIndex diff
                                    index = realIndex
                                    break
                                }
                            }
                            else {
                                if (x.value > nextIndexValue * (1 - _swipeSensitivity)) { // current index is best because its diff is smaller than nextIndex diff
                                    index = realIndex
                                    break
                                }
                            }
                        }
                    }
                }
                else {
                    const arr = xPositions.slice(0, middlePosition + 1)


                    for (let i = arr.length - 1; i >= 0; i--) {
                        const nextIndex = i - 1
                        // const indexValue = arr[i]
                        const nextIndexValue = arr[nextIndex]

                        // console.log({ index: i, indexValue, nextIndexValue, currentIndexIsBest: x.value < nextIndexValue * (1 -_swipeSensitivity) })

                        if (!nextIndexValue) { // end reached
                            if (i === swipePosition) {// else we have difficulties swiping when reaching end and wanting to get away from it
                                if (x.value < i * _swipeSensitivity) index = i
                                else index = i + 1
                            }
                            else index = i
                        }
                        else {
                            if (i === swipePosition) {
                                if (x.value < nextIndexValue * _swipeSensitivity) { // current index is best because its diff is smaller than nextIndex diff
                                    index = i
                                    break
                                }
                            }
                            else {
                                if (x.value < nextIndexValue * (1 - _swipeSensitivity)) { // current index is best because its diff is smaller than nextIndex diff
                                    index = i
                                    break
                                }
                            }
                        }
                    }
                }
            }



            // console.log('onGestureEnd', x.value, { xPositions, index, containerWidth })
            setXAnimated(xPositions[index]!)
            runOnJS(setSwipePositionAndUnsetDisplayAll)(index)
            runOnJS(_onPress)(index, index !== swipePosition)
            if (overswipeLeft && !inOverswipeLeftNoActionArea) runOnJS(_onOverswipeLeft)()
            else if (overswipeRight && !inOverswipeRightNoActionArea) runOnJS(_onOverswipeRight)()
        })
        .minPointers(1)
        .maxPointers(1)

    if (simulatneousGestureHandlers) pan.simultaneousWithExternalGesture(simulatneousGestureHandlers)
    return (
        <View style={[presetContainerWidth ? { width: presetContainerWidth } : GlobalStyles.width100, GlobalStyles.height100, style]} onLayout={onLayout}>
            {containerWidth && (
                <Animated.View style={[!displayAllItems && { transform: [{ translateX: (-middlePosition * positionOffset) }] }]}>
                    <GestureDetector gesture={pan}>
                        <Animated.View style={[{ width: displayAllItems ? '100%' : `${100 * positions.length}%` }, styles.swipeContainer, animation]}>
                            {content.map((item, index) => {
                                const onPress = getOnPressSwipeItemHandler(index)
                                return (
                                    <SwipeItem
                                        key={`swipe-container-item-${index}`}
                                        width={positionOffset}
                                        swipePosition={swipePosition}
                                        displayAll={displayAll}
                                        index={index}
                                        content={item}

                                        _disableOptimizations={_disableOptimizations}
                                        contentStyle={contentStyle}
                                        onPress={onPress}
                                    />
                                )
                            })}
                        </Animated.View>
                    </GestureDetector>
                </Animated.View>
            )}

            {swipeableIndicator && <SwipeableIndicatorOverlay theme={swipeableIndicator.theme} displaySwipeableIndicator={(
                !onOverswipeLeft && swipePosition === 0 ? 'right' : // only display right if no more positions left and we cannot overswipe
                    !onOverswipeRight && swipePosition === content.length - 1 ? 'left' : // only display left if no more positions right and we cannot overswipe
                        true
            )} />}
        </View>
    )
}


interface SwipeItemProps {
    width: number
    swipePosition: number
    index: number
    content: JSX.Element
    displayAll: boolean

    _disableOptimizations?: true // Settings
    contentStyle: StyleProp<ViewStyle> // Style
    onPress: (() => void) | undefined
}
const SwipeItem: React.FC<SwipeItemProps> = React.memo(({
    width,
    swipePosition,
    index,
    content,
    displayAll,

    _disableOptimizations,
    contentStyle,
    onPress,
}) => {
    // console.log({ swipePosition, index, width })
    const shouldFreeze = useMemo(() => !displayAll && swipePosition !== index, [displayAll, swipePosition, index])
    const _content = useMemo(() => _disableOptimizations ? content : <Freeze freeze={shouldFreeze} placeholder={<View style={[{ width }, GlobalStyles.height100]} />}>{content}</Freeze>, [content, _disableOptimizations, shouldFreeze])

    return (
        <GestureHandlerContentButton onPress={onPress} style={[{ width, maxWidth: width }, GlobalStyles.height100]}>
            <View style={[GlobalStyles.width100, GlobalStyles.height100, contentStyle]}>
                {/*
                    IMPORTANT: All scroll components inside SwipeContainer.tsx have to use ScrollView or FlatList from react-native-gesture-handler
                    Eg. with FlashList: <FlashList ... renderScrollComponent={ScrollView}/>
                */}
                {_content}
            </View>
        </GestureHandlerContentButton>
    )
})


const styles = StyleSheet.create({
    swipeContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        overflow: 'hidden'
    }
});

export default React.memo(SwipeContainer)