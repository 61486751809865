import MD5 from "./MD5"

// const getUniqueElements = (value:number, index:number, self:any) => self.indexOf(value) === index
export const stringArrayToDisplayString = (array: string[], separator = ', ') => {
    'worklet'
    let str = ''
    array.forEach((element, i) => {
        'worklet'
        if (i !== 0) str += (separator + element)
        else str += element
    })
    return str
}

export const stringToNoun = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)


export function alignArrays<T extends {}>(referenceArray: T[], targetArray: T[]){
    const missingElements:typeof referenceArray = []
    const incorrectElements:typeof referenceArray = []
    referenceArray.forEach((element, i) => {
        if (i < targetArray.length && MD5.sum(JSON.stringify(element)) !== MD5.sum(JSON.stringify(targetArray[i]))) { // replace incorrect messages
            incorrectElements.push(element)
        }
        else if (i >= targetArray.length) { // add missing messages
            missingElements.push(element)
        }
    })
    return { missingElements, incorrectElements }
}

export const roundTo2DecimalPlaces = (n: number) => {
    'worklet'
    return Math.round(n * 100) / 100
}

export const removeNewlines = (str: string) => str.replace(/\s/g, ' ')



export const calculateLineHeight = (fontSize: number) => parseInt((fontSize * 1.5).toString(), 10)