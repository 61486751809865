import Theme from "../../styles/Theme"

// Mock Implementation
export type color = string
export type personalTheme = {
    useDarkMode: boolean,
    accentColor: color,
    layoutColor: color
}


export default abstract class MemberStorage{
    static getTheme() {
        return Theme.defaultPersonalTheme
    }
}