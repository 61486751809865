import React, { Children, PropsWithChildren, useMemo, useRef, useState } from 'react';
import { NativeSyntheticEvent, StyleProp, StyleSheet, TextInputEndEditingEventData, TextInputFocusEventData, TextInputKeyPressEventData, TextInputProps, TextInputSubmitEditingEventData, TextStyle, View, ViewStyle } from 'react-native';
import { TextInput as _TextInput } from 'react-native-gesture-handler';
import StateAPI from '../../apis/ui/StateAPI';
import { MIN_TEXT_INPUT_HEIGHT, screenWidth } from '../../env';
import Theme, { ThemeProps } from '../../styles/Theme';
import GlobalStyles from '../../styles/global-styles';
import { EditButton, GestureHandlerContentButton } from './Buttons';
import { Text } from './Text';

interface Props extends TextInputProps {
    theme: Theme
    error?: boolean
    warn?: boolean
    customStyle?: StyleProp<TextStyle>
    customRef?: React.RefObject<_TextInput>
    onSubmit?: (() => void)
    submittableWithErrors?: boolean
}




const TextInput: React.FC<Props> = React.memo((props) => {
    delete props.style // deletes all styles
    const { warn, error, theme, customStyle, customRef, value, onSubmit, submittableWithErrors, onEndEditing, onBlur, onKeyPress, onSubmitEditing, multiline } = props
    const color = (value && value.length !== 0) ?
        (warn ? Theme.statics.warn : error ? Theme.statics.error : theme.colors.lightAccent)
        : theme.colors.lightAccent


    const __onSubmit = onSubmit ?
        () => {
            StateAPI.dismissKeyboard()
            onSubmit()
        } :
        multiline ?
            () => { } :
            () => StateAPI.dismissKeyboard()


    const _onSubmit = submittableWithErrors ?
        __onSubmit :
        ((!warn && !error) ? __onSubmit : undefined)

    const { _onEndEditing, _onBlur, _onKeyPress, _onSubmitEditing, } = useMemo(() => {
        const _onEndEditing = onEndEditing || _onSubmit
        const _onBlur = onBlur || _onSubmit
        const __onKeyPress = onKeyPress || _onSubmit || (() => { })
        const _onKeyPress = ((e: NativeSyntheticEvent<TextInputKeyPressEventData>) => { if (e.nativeEvent.key === 'Enter') __onKeyPress(e) })
        const _onSubmitEditing = onSubmitEditing || _onSubmit
        return { _onEndEditing, _onBlur, _onKeyPress, _onSubmitEditing, }
    }, [_onSubmit, onEndEditing, onBlur, onKeyPress, onSubmitEditing])

    return (
        <_TextInput
            ref={customRef}
            style={[GlobalStyles.text, styles.text, theme.styles.textLight, { padding: 0, overflow: 'scroll' }, customStyle]}
            placeholderTextColor={color}
            clearButtonMode='while-editing'
            clearTextOnFocus={false}
            contextMenuHidden={false}
            enablesReturnKeyAutomatically={true}
            importantForAutofill='no'
            keyboardAppearance={theme.type}
            selectionColor={theme.colors.layoutColor}
            cursorColor={theme.colors.layoutColor}
            selectTextOnFocus={true}
            autoCorrect={false}
            autoComplete='off'
            secureTextEntry={false}
            blurOnSubmit={false}

            // onSubmit
            onEndEditing={_onEndEditing}
            onBlur={_onBlur}
            onKeyPress={_onKeyPress}
            onSubmitEditing={_onSubmitEditing}

            {...props}
        />
    )
})



export const TextInputGroup: React.FC<PropsWithChildren<ThemeProps>> = React.memo(({ theme, children }) => (
    <View style={[theme.styles.backgroundTransparentDark, GlobalStyles.padding, GlobalStyles.width100, GlobalStyles.borderLeftRight, theme.styles.borderLight, GlobalStyles.borderRadius]}>
        {children}
    </View>
))

interface TextInputWithContainerProps extends Props {
    containerStyle?: StyleProp<ViewStyle>
    customLabel?: string
}
export const TextInputWithLabel: React.FC<TextInputWithContainerProps> = React.memo((props) => {
    const { placeholder, customLabel, containerStyle, error, warn, theme, editable, value, onSubmit, submittableWithErrors } = props
    const color = (value && value.length !== 0) ?
        (warn ? Theme.statics.warn : error ? Theme.statics.error : theme.colors.accentColor) :
        theme.colors.light

    const _onSubmit = submittableWithErrors ?
        onSubmit :
        ((!warn && !error) ? onSubmit : undefined)

    return (
        <View style={[GlobalStyles.flexBetween, styles.text, GlobalStyles.horizontalPadding, containerStyle]}>
            <Text theme={theme} text={{ text: `${customLabel || placeholder}:`, style: [GlobalStyles.paddingRight] }} />
            <TextInput
                {...props}
                warn={undefined}
                error={undefined}
                onSubmit={_onSubmit}
                customStyle={[GlobalStyles.flex, props.customStyle, editable !== false && [GlobalStyles.borderBottom, { minHeight: undefined, borderBottomWidth: StyleSheet.hairlineWidth, borderColor: color }]]}
            />
        </View>
    )
})

const styles = StyleSheet.create({
    text: {
        minHeight: MIN_TEXT_INPUT_HEIGHT
    }
});

export default TextInput
export type TextInputType = _TextInput


// Can currently not handle props related to onSubmit
export const TextInputWithEditButton: React.FC<TextInputWithContainerProps> = React.memo((props) => {
    const { theme, editable, customStyle, containerStyle, onSubmit, onEndEditing, onBlur, onKeyPress, onSubmitEditing } = props
    const [editing, setEditing] = useState(false)
    const toggleEditing = () => {
        const wasEditing = editing
        StateAPI.executeAnimationFunctions([
            () => setEditing(!editing),
            () => {
                if (!wasEditing) textInput.current?.focus()
            }
        ])
    }

    const textInput = useRef<_TextInput>(null)


    const _onSubmit = () => {
        setEditing(false)
        if (onSubmit) onSubmit()
    }




    const _editable = editing && editable !== false
    return editable !== false ? (
        <GestureHandlerContentButton style={[GlobalStyles.flexBetween, containerStyle]} disabled={_editable} onPress={toggleEditing}>
            {editing ?
                <TextInput
                    {...props}
                    customStyle={[customStyle, { maxWidth: '90%' }]}
                    customRef={textInput}
                    onSubmit={_onSubmit}
                    editable={_editable}
                /> :
                <Text text={{ text: props.value?.toString() || '', style: customStyle }} {...props} />
            }
            <EditButton theme={theme} style={GlobalStyles.marginLeft} onPress={toggleEditing} iconColor={_editable ? undefined : theme.colors.light} />
        </GestureHandlerContentButton>

    ) : (
        <TextInput {...props} />
    )
})