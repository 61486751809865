import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { StyleProp, StyleSheet, TextStyle, View, Text as _Text } from "react-native";
import { color } from "../../apis/storage/MemberStorage";
import Theme, { ThemeProps } from "../../styles/Theme";
import GlobalStyles from "../../styles/global-styles";
import { CSPRNG } from "../../apis/qunai";
import DeviceAPI from "../../apis/device/DeviceAPI";
import { ContentButton } from "./Buttons";

type highlightStyle = ("bold" | "lean") | ("lightColor" | "lightAccentColor" | 'darkColor' | "accentColor" | "layoutColor") | ("accentColorUnderlined" | "layoutColorUnderlined" | "underlined")
type linkTextChunk = { text: string, link: string | (() => void) }
type highlightedTextChunk = { text: string, highlight: ((highlightStyle)[]) | highlightStyle }
export type highlightableText = string | ((string | highlightedTextChunk | linkTextChunk)[])


type _textObj = {
    text: highlightableText
    color?: color,
    style?: StyleProp<TextStyle>
}

type textObj = _textObj | highlightableText
export interface TextProps {
    text: textObj
}


export interface TextComponentProps extends TextProps {
    theme: Theme
    numberOfLines?: number
}



export const Text: React.FC<TextComponentProps> = React.memo(({ theme, numberOfLines, text }) => {
    let textColor: color
    let textStyle: StyleProp<TextStyle>
    let _text: highlightableText

    if (typeof text === 'string' || Array.isArray(text)) {
        textColor = theme.colors.light
        textStyle = []
        _text = text
    }
    else {
        textColor = text.color || theme.colors.light
        textStyle = text.style || []
        _text = text.text
    }



    if (typeof _text === 'string') return <_Text numberOfLines={numberOfLines} style={[GlobalStyles.text, { color: textColor, overflow: numberOfLines != undefined ? 'hidden' : undefined }, textStyle]}>{_text}</_Text>
    else {
        const addWhitespaceToTextElements = (text: string, i: number) => i === (_text.length - 1) ? text : (text + ' ')

        return (
            <_Text style={[textStyle, style.textStyleReset, { overflow: numberOfLines != undefined ? 'hidden' : undefined }]}>
                {_text.map((chunk, i) => {
                    const key = `text-${CSPRNG.generateUnsafeRandomString(12)}-chunk-${i}`
                    if (typeof chunk === 'string') return <_Text key={key} numberOfLines={numberOfLines} style={[GlobalStyles.text, { color: textColor }, textStyle, style.viewStyleReset]}>{addWhitespaceToTextElements(chunk, i)}</_Text>
                    else {
                        const { text, link } = chunk as linkTextChunk
                        if (link) {
                            const [onPress, onLongPress] = typeof link === 'string' ? [async () => await DeviceAPI.openLink(link), () => DeviceAPI.copyToClipboard(link)] : [link, link]
                            return <_Text onPress={onPress} onLongPress={onLongPress} key={key} numberOfLines={numberOfLines} style={[GlobalStyles.text, { color: textColor }, theme.styles.textLightAccent, GlobalStyles.underlinedText, textStyle, style.viewStyleReset]}>{addWhitespaceToTextElements(text, i)}</_Text>
                        }
                        else {
                            const { highlight } = chunk as highlightedTextChunk
                            const additionalTextStyle: StyleProp<TextStyle> = []


                            if (typeof highlight === 'string') {
                                if (highlight === 'bold') additionalTextStyle.push(GlobalStyles.boldText)
                                else if (highlight === 'lean') additionalTextStyle.push(GlobalStyles.leanText)
                                else if (highlight === 'accentColor') additionalTextStyle.push(theme.styles.textAccentColor)
                                else if (highlight === 'layoutColor') additionalTextStyle.push(theme.styles.textLayoutColor)
                                else if (highlight === 'lightColor') additionalTextStyle.push(theme.styles.textLight)
                                else if (highlight === 'lightAccentColor') additionalTextStyle.push(theme.styles.textLightAccent)
                                else if (highlight === 'darkColor') additionalTextStyle.push(theme.styles.textDark)
                                else if (highlight === 'accentColorUnderlined') additionalTextStyle.push(theme.styles.borderAccentColor)
                                else if (highlight === 'layoutColorUnderlined') additionalTextStyle.push(theme.styles.borderLayoutColor)
                                else if (highlight === 'underlined') additionalTextStyle.push(theme.styles.borderLight)
                            }
                            else {
                                if (highlight.includes('bold')) additionalTextStyle.push(GlobalStyles.boldText)
                                else if (highlight.includes('lean')) additionalTextStyle.push(GlobalStyles.leanText)

                                if (highlight.includes('accentColor')) additionalTextStyle.push(theme.styles.textAccentColor)
                                else if (highlight.includes('layoutColor')) additionalTextStyle.push(theme.styles.textLayoutColor)
                                else if (highlight.includes('lightColor')) additionalTextStyle.push(theme.styles.textLight)
                                else if (highlight.includes('lightAccentColor')) additionalTextStyle.push(theme.styles.textLightAccent)
                                else if (highlight.includes('darkColor')) additionalTextStyle.push(theme.styles.textDark)

                                if (highlight.includes('accentColorUnderlined')) additionalTextStyle.push(GlobalStyles.borderBottom, theme.styles.borderAccentColor)
                                else if (highlight.includes('layoutColorUnderlined')) additionalTextStyle.push(GlobalStyles.borderBottom, theme.styles.borderLayoutColor)
                                else if (highlight.includes('underlined')) additionalTextStyle.push(GlobalStyles.borderBottom, theme.styles.borderLight)
                            }


                            return <_Text key={key} numberOfLines={numberOfLines} style={[GlobalStyles.text, { color: textColor }, additionalTextStyle, textStyle, style.viewStyleReset]}>{addWhitespaceToTextElements(text, i)}</_Text>
                        }
                    }
                })}
            </_Text >
        )
    }
})
export const Heading: React.FC<TextComponentProps> = React.memo(({ theme, numberOfLines, text }) => {
    const _text: _textObj = (typeof text === 'string' || Array.isArray(text)) ? { text } : text
    _text.style = [GlobalStyles.heading, _text.style]

    return <Text numberOfLines={numberOfLines} theme={theme} text={_text} />
})
export const BigHeading: React.FC<TextComponentProps> = React.memo(({ theme, numberOfLines, text }) => {
    const _text: _textObj = (typeof text === 'string' || Array.isArray(text)) ? { text } : text
    _text.style = [GlobalStyles.bigHeading, _text.style]

    return <Text numberOfLines={numberOfLines} theme={theme} text={_text} />
})
export const SmallText: React.FC<TextComponentProps> = React.memo(({ theme, numberOfLines, text }) => {
    const _text: _textObj = (typeof text === 'string' || Array.isArray(text)) ? { text } : text
    _text.style = [GlobalStyles.smallText, _text.style]

    return <Text numberOfLines={numberOfLines} theme={theme} text={_text} />
})
export const VerySmallText: React.FC<TextComponentProps> = React.memo(({ theme, numberOfLines, text }) => {
    const _text: _textObj = (typeof text === 'string' || Array.isArray(text)) ? { text } : text
    _text.style = [GlobalStyles.verySmallText, _text.style]

    return <Text numberOfLines={numberOfLines} theme={theme} text={_text} />
})


interface Props extends ThemeProps {
    text: string
}

export const EncircledText: React.FC<Props> = React.memo(({ theme, text }) => (
    <View style={GlobalStyles.marginBottom}>
        <View style={[GlobalStyles.flexCenter, GlobalStyles.border, theme.styles.borderLayoutColor, GlobalStyles.borderRadius, style.encircledTextPadding]}>
            <VerySmallText theme={theme} text={{ text, color: theme.colors.layoutColor, style: [GlobalStyles.textAlignCenter] }} />
        </View>
    </View>
))

const style = StyleSheet.create({
    encircledTextPadding: {
        paddingHorizontal: GlobalStyles.horizontalPadding.paddingHorizontal / 3,
    },
    textStyleReset: {
        color: undefined,
        fontFamily: undefined,
        fontSize: undefined,
        fontStyle: undefined,
        fontWeight: undefined,
        letterSpacing: undefined,
        lineHeight: undefined,
        textAlign: undefined,
        textDecorationLine: undefined,
        textDecorationStyle: undefined,
        textDecorationColor: undefined,
        textShadowColor: undefined,
        textShadowOffset: undefined,
        textShadowRadius: undefined,
        textTransform: undefined,
        fontVariant: undefined,
        writingDirection: undefined,
        // textAlignVertical: undefined,
        verticalAlign: undefined,
        includeFontPadding: undefined,
    },
    viewStyleReset: {
        //ViewStyle
        backfaceVisibility: undefined,
        backgroundColor: undefined,
        // borderBlockColor: undefined,
        // borderBlockEndColor: undefined,
        // borderBlockStartColor: undefined,
        borderBottomColor: undefined,
        borderBottomEndRadius: undefined,
        borderBottomLeftRadius: undefined,
        borderBottomRightRadius: undefined,
        borderBottomStartRadius: undefined,
        borderColor: undefined,
        borderCurve: undefined,
        borderEndColor: undefined,
        borderEndEndRadius: undefined,
        borderEndStartRadius: undefined,
        borderLeftColor: undefined,
        borderRadius: undefined,
        borderRightColor: undefined,
        borderStartColor: undefined,
        borderStartEndRadius: undefined,
        borderStartStartRadius: undefined,
        borderStyle: undefined,
        borderTopColor: undefined,
        borderTopEndRadius: undefined,
        borderTopLeftRadius: undefined,
        borderTopRightRadius: undefined,
        borderTopStartRadius: undefined,
        opacity: undefined,
        elevation: undefined,
        pointerEvents: undefined,
        // FlexStyle
        alignContent: undefined,
        alignItems: undefined,
        alignSelf: undefined,
        aspectRatio: undefined,
        borderBottomWidth: undefined,
        borderEndWidth: undefined,
        borderLeftWidth: undefined,
        borderRightWidth: undefined,
        borderStartWidth: undefined,
        borderTopWidth: undefined,
        borderWidth: undefined,
        bottom: 'auto',
        display: undefined,
        end: 'auto',
        flex: undefined,
        flexBasis: 'auto',
        flexDirection: undefined,
        rowGap: undefined,
        gap: undefined,
        columnGap: undefined,
        flexGrow: undefined,
        flexShrink: undefined,
        flexWrap: undefined,
        height: 'auto',
        justifyContent: undefined,
        left: 'auto',
        margin: 'auto',
        marginBottom: 'auto',
        marginEnd: 'auto',
        marginHorizontal: 'auto',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginStart: 'auto',
        marginTop: 'auto',
        marginVertical: 'auto',
        maxHeight: 'auto',
        maxWidth: 'auto',
        minHeight: 'auto',
        minWidth: 'auto',
        overflow: undefined,
        padding: 'auto',
        paddingBottom: 'auto',
        paddingEnd: 'auto',
        paddingHorizontal: 'auto',
        paddingLeft: 'auto',
        paddingRight: 'auto',
        paddingStart: 'auto',
        paddingTop: 'auto',
        paddingVertical: 'auto',
        position: undefined,
        right: 'auto',
        start: 'auto',
        top: 'auto',
        width: 'auto',
        zIndex: undefined,
        // ShadowStyleIOS
        // direction: undefined,
        shadowColor: undefined,
        shadowOffset: undefined,
        shadowOpacity: undefined,
        shadowRadius: undefined,
        // TransformsStyle
        transform: undefined,
        // transformOrigin: undefined,
        // transformMatrix: undefined,
        // rotation: undefined,
        // scaleX: undefined,
        // scaleY: undefined,
        // translateX: undefined,
        // translateY: undefined,
    }
})


export const Tip: React.FC<Props> = React.memo(({ theme, text }) => {
    const textObj = typeof text === 'object' ? text : { text }

    return (
        <Text theme={theme} text={{ color: theme.colors.lightAccent, style: [GlobalStyles.smallText, GlobalStyles.textAlignCenter, GlobalStyles.leanText], ...textObj }} />
    )
})
export const SyncTip: React.FC<ThemeProps> = React.memo(({ theme }) => {
    const $syncTip = useTranslation().t('global.sync.$syncTip')
    return (
        <Tip theme={theme} text={$syncTip} />
    )
})