import React, { PropsWithChildren, useEffect, useState } from "react"
import { NativeSyntheticEvent, NativeScrollEvent, DimensionValue, View, ScrollView } from "react-native"
import { ContentButton, ExpandableButton } from "../../client/components/Assets/Buttons"
import Separator from "../../client/components/Assets/Separator"
import { highlightableText, BigHeading, SmallText, Text } from "../../client/components/Assets/Text"
import { TextInputGroup } from "../../client/components/Assets/TextInput"
import { screenWidth, screenHeight, HEADER_HEIGHT, longPressDelay } from "../../client/env"
import GlobalStyles from "../../client/styles/global-styles"
import { ThemeProps } from "../../client/styles/Theme"
import { CSPRNG } from "../../client/apis/qunai"
import Icon, { icon, IconText } from "../../client/components/Assets/Icon"

type recursivePageContent = { $title?: highlightableText, icon?: icon, $content?: pageContent[] }
type pageContent = highlightableText | { resource: string } | recursivePageContent
type content = { $title?: string, $subtitle?: highlightableText, $page: pageContent[] }
interface WebPageProps extends PropsWithChildren<ThemeProps> {
    scrollRef?: React.RefObject<ScrollView>
    onScroll?: (event: NativeSyntheticEvent<NativeScrollEvent>) => void
    scrollEventThrottle?: number
    maxWidth?: DimensionValue
    header?: JSX.Element
    $content?: content
    resources?: {}
    pageExpandable?: boolean
}
const WebPage: React.FC<WebPageProps> = ({ theme, scrollRef, onScroll, scrollEventThrottle, maxWidth, header, $content, resources, pageExpandable, children }) => (
    <View style={[GlobalStyles.screenWidth, GlobalStyles.screenHeight, GlobalStyles.flexCenter]}>
        <ScrollView ref={scrollRef} onScroll={onScroll} scrollEventThrottle={scrollEventThrottle} style={[{ flexGrow: 0, maxHeight: '70%', maxWidth: maxWidth || (screenWidth > screenHeight ? '75%' : '100%'), marginTop: HEADER_HEIGHT }]}>
            <TextInputGroup theme={theme}>
                {header && (
                    <View style={GlobalStyles.flexCenter}>
                        {header}
                        {($content || children) && <Separator big />}
                    </View>
                )}
                {$content && <PageContent theme={theme} $content={$content} resources={resources} pageExpandable={pageExpandable} />}
                {children && (
                    <View style={GlobalStyles.flexCenter}>
                        {children}
                    </View>
                )}
            </TextInputGroup>
        </ScrollView>
    </View>
)



interface PageContentProps extends ThemeProps {
    $content: content
    resources?: {}
    pageExpandable?: boolean
}
const PageContent: React.FC<PageContentProps> = ({ theme, $content, resources, pageExpandable }) => {
    const { $title, $subtitle, $page } = $content
    const pageType = $title || (typeof $subtitle === 'string' ? $subtitle : CSPRNG.generateUnsafeRandomString(12))

    return (
        <View style={[GlobalStyles.borderRadius]}>
            {$title && (
                <>
                    <BigHeading theme={theme} text={$title} />
                    <Separator big />
                </>
            )}
            {$subtitle && (
                <>
                    <Text theme={theme} text={$subtitle} />
                    <Separator big />
                </>
            )}
            <Separator big />
            <View style={GlobalStyles.horizontalMargin}>
                {$page.map(($pageContent, i) => <RecursivePageContent key={`${$title}-root-array-page-content-${i}`} theme={theme} $content={$pageContent} resources={resources} pageExpandable={pageExpandable} depth={0} pageType={pageType} />)}
            </View>
        </View>
    )
}




interface RecursivePageContentProps extends ThemeProps {
    resources?: {}
    $content: pageContent
    pageExpandable?: boolean
    depth: number
    pageType: string
}
const RecursivePageContent: React.FC<RecursivePageContentProps> = React.memo(({ theme, $content, resources, pageExpandable, depth, pageType }) => {
    const depthIs0 = depth === 0
    const TextComponent = depthIs0 ? Text : SmallText
    const horizontalMargin = !depthIs0 && GlobalStyles.horizontalMargin
    const [displayContent, setDisplayContent] = useState(false)
    const onToggleDisplayContent = () => setDisplayContent(!displayContent)



    if (typeof $content === 'string' || Array.isArray($content)) return <TextComponent theme={theme} text={{ text: $content, style: [horizontalMargin] }} />
    //@ts-ignore
    else if (resources && $content.resource) return <View style={GlobalStyles.flexCenter}>{resources[$content.resource]()}</View>
    else {
        const { $title, icon, $content: _$content } = $content as recursivePageContent

        const Content = _$content && _$content.map(($nestedContent, i) => <RecursivePageContent key={`${pageType}-page-content-${depth}-${i}`} theme={theme} $content={$nestedContent} resources={resources} pageExpandable={pageExpandable} depth={depth + 1} pageType={pageType} />)
        const _TitleContent = ($title && icon) ? <IconText theme={theme} text={$title} icon={icon} style={GlobalStyles.flex} /> :
            icon ? <Icon iconSource={icon.iconSource} name={icon.name} color={theme.colors.accentColor} /> :
                $title ? <Text theme={theme} text={$title} /> : undefined
        
        if (_TitleContent) {
            return (
                <View style={[horizontalMargin]}>
                    {pageExpandable ? (
                        <ContentButton disabled={!Content} style={[GlobalStyles.flexBetween, GlobalStyles.horizontalPadding, GlobalStyles.borderLeftRight, theme.styles.backgroundTransparentDark, theme.styles.borderLight, GlobalStyles.borderRadius]} onPress={onToggleDisplayContent}>
                            {_TitleContent}
                            {Content && <ExpandableButton onPress={onToggleDisplayContent} onLongPress={onToggleDisplayContent} longPressDelay={longPressDelay} theme={theme} expanded={displayContent} style={[GlobalStyles.marginLeft]} />}
                        </ContentButton>

                    ) : _TitleContent}


                    {(Content && (pageExpandable ? displayContent : true)) ? (
                        <View style={[pageExpandable && [GlobalStyles.borderLeftBottom, theme.styles.borderLayoutColor, GlobalStyles.borderRadius], GlobalStyles.bigMarginBottom, GlobalStyles.bigVerticalPadding]}>
                            {Content}
                        </View>
                    ) : (
                        <Separator big={depthIs0} />
                    )}
                </View>
            )
        }
        else if (Content) {
            return (
                <View style={[horizontalMargin]}>
                    {Content}
                </View>
            )
        }
    }
})





export default React.memo(WebPage)