import React, { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { NativeScrollEvent, NativeSyntheticEvent, View } from "react-native";
import { GestureHandlerRootView, ScrollView } from "react-native-gesture-handler";
import LinearGradient from "react-native-linear-gradient";
import { useSharedValue } from "react-native-reanimated";
import { ContentButton, CopyToClipboardButtonWithLabel, ExpandableButton, IconButton, TextIconButton, ThemedButton } from "./client/components/Assets/Buttons";
import Separator from "./client/components/Assets/Separator";
import Slider from "./client/components/Assets/Slider";
import SwipeContainer from "./client/components/Assets/SwipeContainer";
import { BigHeading, Text, Tip } from "./client/components/Assets/Text";
import { ACCOUNTS, ANDROID_RESOURCE_URI, DEFAULT_ACCENT_COLOR, DEFAULT_BIG_ICON_SIZE, DEFAULT_ICON_SIZE, DEFAULT_LAYOUT_COLOR, HEADER_HEIGHT, IMPRESSUM_URI, IOS_RESOURCE_URI, isLandscapeOrientation, LINUX_RESOURCE_URI, MACOS_RESOURCE_URI, PRIVACY_POLICY_URI, screenHeight, screenWidth, WINDOWS_RESOURCE_URI } from "./client/env";
import GlobalStyles, { DEFAULT_HORIZONTAL_DISTANCE } from "./client/styles/global-styles";
import Theme, { ThemeProps } from "./client/styles/Theme";



import DeviceAPI from "./client/apis/device/DeviceAPI";
import ForegroundServiceAPI from "./client/apis/notifications/ForegroundServiceAPI";
import { appName, SUPPORT_EMAIL } from "./client/apis/qunai";
import LanguageAPI from "./client/apis/ui/LanguageAPI";
import SvgAnimationAPI from "./client/apis/ui/SvgAnimationAPI";
import LoadingAnimation from "./client/components/Assets/Animations/LoadingAnimation";
import ThickLogoAnimation from "./client/components/Assets/Animations/ThickLogoAnimation";
import LanguageSelectionOverlay from "./client/components/Assets/Overlays/LanguageSelectionOverlay";
import OverlayContainer from "./client/components/Assets/Overlays/OverlayContainer";
import AnonymizationDeSVG from "./assets/images/svgs/Anonymization-de.svg";
import AnonymizationEnSVG from "./assets/images/svgs/Anonymization-en.svg";
import SecurityDeSVG from "./assets/images/svgs/Security-de.svg";
import SecurityEnSVG from "./assets/images/svgs/Security-en.svg";
import SpeedDeSVG from "./assets/images/svgs/Speed-de.svg";
import SpeedEnSVG from "./assets/images/svgs/Speed-en.svg";
import ParticlesOverlay from "./components/Assets/Overlays/ParticlesOverlay";
import WebPage from "./components/Assets/WebPage";

import StateAPI from "./client/apis/ui/StateAPI";
import de from "./assets/languages/de.json";
import en from "./assets/languages/en.json";
import useOnLinkPress from "./hooks/useOnLinkPress";

// ScrollView
const scrollEventThrottle = 0
const scrollSensitivity = screenHeight * 0.1
const autoScrollTimeout = 650

const imageStyles = isLandscapeOrientation ? { width: screenWidth / 2 } : { width: '100%' }

const DOWNLOAD_URIS = [
  ANDROID_RESOURCE_URI,
  IOS_RESOURCE_URI,
  WINDOWS_RESOURCE_URI,
  LINUX_RESOURCE_URI,
  MACOS_RESOURCE_URI
]

function App() {
  const [theme, setTheme] = useState(new Theme(Theme.defaultPersonalTheme))
  const [link, setLink] = useState('')

  useMemo(() => {
    DeviceAPI.initialize(
      (useDarkMode) => {
        setTheme(new Theme({ accentColor: DEFAULT_ACCENT_COLOR, layoutColor: DEFAULT_LAYOUT_COLOR, useDarkMode }))
      },
      (link) => {
        if (link === PRIVACY_POLICY_URI) setMenuActive('PRIVACY_POLICY')
        else if (link === IMPRESSUM_URI) setMenuActive('IMPRESSUM')

        setLink(link)
      }
    )
    SvgAnimationAPI.initialize()
    LanguageAPI.initialize(DeviceAPI, ForegroundServiceAPI, { en, de });
  }, [])
  const { Home, Why, How, Menu, Install, Questions } = useTranslation().t('web', { returnObjects: true, SUPPORT_EMAIL, MEMBER_MAX_ATTACHMENT_SIZE: ACCOUNTS[0].features.Messenger.maxAttachmentSize, IMPRESSUM_URI }) as any

  // Main Page Slider
  const [swipePosition, setSwipePosition] = useState(1)

  // ScrollY Slider
  const scrollYLastChange = useSharedValue(Date.now())
  const scrollY = useSharedValue(0)
  const scrollRef = useRef<ScrollView>(null)
  const [scrollYPosition, setScrollYPosition] = useState(0)
  const [displayScrollBackUpButton, setDisplayScrollBackUpButton] = useState(false)
  const scrollTo = useCallback((y: number) => {
    scrollY.value = y
    scrollYLastChange.value = Date.now()
    scrollRef.current?.scrollTo({ y })

    if (y !== 0) setDisplayScrollBackUpButton(true)
    else setDisplayScrollBackUpButton(false)

    setScrollYPosition(Math.round(y / screenHeight))
  }, [scrollY, scrollYLastChange, scrollYPosition, scrollRef])

  const onScroll = (e: NativeSyntheticEvent<NativeScrollEvent>) => {
    const now = Date.now()
    if (now > scrollYLastChange.value + autoScrollTimeout) {
      const newScrollY = e.nativeEvent.contentOffset.y
      const currentScrollY = scrollY.value

      const scrollContentHeight = e.nativeEvent.layoutMeasurement.height

      if (newScrollY > (currentScrollY + scrollSensitivity)) scrollTo(currentScrollY + scrollContentHeight)
      else if (newScrollY < (currentScrollY - scrollSensitivity)) scrollTo(currentScrollY - scrollContentHeight)
    }
  }


  // Main Page functions
  const [onLogoPress, onQunaiWeb, onGetQunai, onWhy, onHow, onQuestion, onLanguage, onScrollBackUp] = useMemo(() => ([
    () => {
      setSwipePosition(1)
      scrollTo(0)
    },
    () => {
      setSwipePosition(1)
      scrollTo(screenHeight)
      setPlatform(5)
    },
    () => {
      setSwipePosition(1)
      scrollTo(screenHeight)
      setPlatform(0)
    },
    () => {
      setSwipePosition(0)
    },
    () => {
      setSwipePosition(2)
    },
    () => {
      setSwipePosition(1)
      scrollTo(screenHeight * 2)
      setMenuActive(true)
    },
    () => {
      StateAPI.setDisplayLanguageSelection(true)
      setMenuActive(true)
    },
    () => {
      setSwipePosition(1)
      if (scrollY.value === (screenHeight * 2)) scrollTo(screenHeight)
      else scrollTo(0)
    },
  ]), [scrollY])



  // Platform Slider
  const [platform, setPlatform] = useState(0)
  const currentPlatformContent = useMemo(() => Install.installationGuide[platform], [platform, Install])
  const onDownload = useOnLinkPress({ download: appName, href: DOWNLOAD_URIS[platform] })


  const [menuActive, setMenuActive] = useState<'IMPRESSUM' | 'PRIVACY_POLICY' | boolean>(false)
  const homeGradientColors = ['transparent', theme.colors.transparentDark, theme.colors.transparentDark, 'transparent']

  console.log({ theme: theme.type, link, swipePosition, platform, menuActive })
  return (
    //@ts-ignore
    <GestureHandlerRootView style={[{ overflow: 'hidden', height: '100vh', width: '100vw' }]}>
      <ParticlesOverlay theme={theme} />

      <View style={[GlobalStyles.screenWidth, GlobalStyles.screenHeight]}>
        <SwipeContainer
          disableOptimizations
          swipePosition={swipePosition}
          setSwipePosition={setSwipePosition}
          content={[
            // Why
            <WebPage
              theme={theme}
              $content={Why}
              resources={{
                'Anonymization-en.svg': () => <img src={AnonymizationEnSVG} alt={'Anonymization-en.svg'} style={imageStyles} />,
                'Anonymization-de.svg': () => <img src={AnonymizationDeSVG} alt={'Anonymization-de.svg'} style={imageStyles} />,
                'Speed-de.svg': () => <img src={SpeedDeSVG} alt={'Speed-de.svg'} style={imageStyles} />,
                'Speed-en.svg': () => <img src={SpeedEnSVG} alt={'Speed-en.svg'} style={imageStyles} />,
                'Security-de.svg': () => <img src={SecurityDeSVG} alt={'Security-de.svg'} style={imageStyles} />,
                'Security-en.svg': () => <img src={SecurityEnSVG} alt={'Security-en.svg'} style={imageStyles} />,
              }}
              pageExpandable
            >
              <WhyHowFooter theme={theme} GetQunai={[Home.$GetQunai, onGetQunai]} onLogoPress={onLogoPress} $whyHowFooterTip={Home.$whyHowFooterTip} />
            </WebPage>,

            <View style={[GlobalStyles.screenHeight, GlobalStyles.screenWidth]}>
              <ScrollView ref={scrollRef} onScroll={onScroll} showsVerticalScrollIndicator={false} scrollEventThrottle={scrollEventThrottle}>
                {/* Home */}
                <OverlayContainer top={0} left={0} width={screenWidth} height={screenHeight}>
                  <View style={{ width: '68%', height: '68%' }}>
                    <LoadingAnimation theme={theme} />
                  </View>
                </OverlayContainer>
                <View style={[GlobalStyles.screenHeight, GlobalStyles.screenWidth, GlobalStyles.flexCenter]}>

                  <View style={[GlobalStyles.flexCenter, GlobalStyles.screenWidth, GlobalStyles.verticalPadding, isLandscapeOrientation ? GlobalStyles.flexDirectionRow : GlobalStyles.flexDirectionColumn]}>
                    <LinearGradient colors={isLandscapeOrientation ? homeGradientColors : []} style={[GlobalStyles.flexBetween, isLandscapeOrientation ? GlobalStyles.flexDirectionRow : GlobalStyles.flexDirectionColumn, GlobalStyles.borderRadius, { width: '24%' }]}>
                      <ContentButton style={[GlobalStyles.flex, GlobalStyles.flexEnd]} onPress={onWhy}>
                        <BigHeading theme={theme} text={Why.$title} />
                      </ContentButton>
                      <View style={[isLandscapeOrientation ? { height: 0, ...GlobalStyles.flex, ...GlobalStyles.bigHorizontalMargin, ...GlobalStyles.borderBottom } : { width: 0, height: screenHeight * 0.1, ...GlobalStyles.bigVerticalMargin, ...GlobalStyles.borderLeft }, theme.styles.borderLayoutColor]} />
                    </LinearGradient>

                    <LinearGradient colors={homeGradientColors} style={[GlobalStyles.bigHorizontalMargin]}>
                      <ContentButton onPress={onGetQunai}>
                        <BigHeading theme={theme} text={{ text: Home.$subtitle, style: GlobalStyles.textAlignCenter }} />
                      </ContentButton>
                    </LinearGradient>

                    <LinearGradient colors={isLandscapeOrientation ? homeGradientColors : []} style={[GlobalStyles.flexBetween, isLandscapeOrientation ? GlobalStyles.flexDirectionRow : GlobalStyles.flexDirectionColumn, GlobalStyles.borderRadius, { width: '24%' }]}>
                      <View style={[isLandscapeOrientation ? { height: 0, ...GlobalStyles.flex, ...GlobalStyles.bigHorizontalMargin, ...GlobalStyles.borderBottom } : { width: 0, height: screenHeight * 0.1, ...GlobalStyles.bigVerticalMargin, ...GlobalStyles.borderLeft }, theme.styles.borderLayoutColor]} />
                      <ContentButton style={[GlobalStyles.flex, GlobalStyles.flexStart]} onPress={onHow}>
                        <BigHeading theme={theme} text={How.$title} />
                      </ContentButton>
                    </LinearGradient>
                  </View>
                </View>


                {/* Install */}
                <WebPage
                  theme={theme}
                  $content={currentPlatformContent}
                  pageExpandable
                >
                  <Separator big />
                  <Slider
                    relativeSliderWidth={0.9}
                    theme={theme}
                    positions={Install.platforms.map((key: string, i: number) => ({ key, value: i }))}
                    initialValue={platform}
                    onChange={(value: any) => setPlatform(value)}
                  />
                  {currentPlatformContent.$page.length !== 0 && <ThemedButton theme={theme} text={Install.$download} onPress={onDownload} style={[GlobalStyles.bigVerticalMargin]} />}
                </WebPage>

                {/* Questions */}
                <WebPage
                  theme={theme}
                  $content={Questions.commonQuestions}
                  pageExpandable
                >
                  <View style={[GlobalStyles.width100, GlobalStyles.flexEnd]}>
                    <CopyToClipboardButtonWithLabel theme={theme} text={Questions.$contactUs} clipboard={SUPPORT_EMAIL} onPress={() => DeviceAPI.copyToClipboard(SUPPORT_EMAIL)} />
                  </View>
                </WebPage>
              </ScrollView>
            </View>,

            // How
            <>
              <WebPage
                theme={theme}
                $content={How}
                pageExpandable
              >
                <WhyHowFooter theme={theme} GetQunai={[Home.$GetQunai, onGetQunai]} onLogoPress={onLogoPress} $whyHowFooterTip={Home.$whyHowFooterTip} />
              </WebPage>
            </>
          ]}
          animationConfig={{ stiffness: 100, damping: 100 }}
        />
      </View>


      {/* Header */}
      <LinearGradient colors={[theme.colors.transparentDark, theme.colors.transparentDark, theme.colors.transparentDark, theme.colors.transparentDark, 'transparent']} style={{ position: 'absolute', top: 0 }}>
        <View style={[{ height: HEADER_HEIGHT }, GlobalStyles.screenWidth, GlobalStyles.flexBetween]}>
          <ContentButton style={[GlobalStyles.height100, { width: '12%' }]} onPress={onLogoPress}>
            <ThickLogoAnimation theme={theme} />
          </ContentButton>
          <View style={[GlobalStyles.flexAround, { width: isLandscapeOrientation ? '50%' : '76%' }, GlobalStyles.horizontalPadding]}>
            <ContentButton style={[{ flexBasis: '32%' }, swipePosition === 1 && scrollYPosition === 1 && platform === 5 && theme.styles.backgroundAccentColor]} onPress={onQunaiWeb}>
              <Text theme={theme} text={{ text: Home.$QunaiWeb, style: [GlobalStyles.textAlignCenter] }} />
            </ContentButton>
            <ContentButton style={[{ flexBasis: '32%' }, (swipePosition === 1 ? scrollYPosition === 0 : true) && theme.styles.backgroundAccentColor]} onPress={onLogoPress}>
              <Text theme={theme} text={{ text: Home.$Qunai, style: [GlobalStyles.textAlignCenter, GlobalStyles.boldText] }} />
            </ContentButton>
            <ContentButton style={[{ flexBasis: '32%' }, swipePosition === 1 && scrollYPosition === 1 && platform !== 5 && theme.styles.backgroundAccentColor]} onPress={onGetQunai}>
              <Text theme={theme} text={{ text: Home.$GetQunai, style: [GlobalStyles.textAlignCenter] }} />
            </ContentButton>
          </View>
          <IconButton theme={theme} icon={{ iconSource: 'IonIcon', name: 'menu-outline', color: menuActive ? theme.colors.layoutColor : theme.colors.accentColor }} style={[{ width: '12%' }]} onPress={() => setMenuActive(!menuActive)} />
        </View>
      </LinearGradient>


      {menuActive && (
        <>
          {menuActive !== true && (
            <OverlayContainer
              left={0}
              top={0}
              width={screenWidth}
              height={screenHeight}
              backgroundColor={theme.colors.transparentDark}
              dismiss={() => setMenuActive(false)}
            >
              {menuActive === 'PRIVACY_POLICY' && (
                <WebPage
                  theme={theme}
                  $content={Menu.PrivacyPolicy}
                  pageExpandable
                />
              )}
              {menuActive === 'IMPRESSUM' && (
                <WebPage
                  theme={theme}
                  $content={Menu.Impressum}
                  pageExpandable
                />
              )}
            </OverlayContainer>
          )}
          {/*//@ts-ignore*/}
          <OverlayContainer right={0} top={HEADER_HEIGHT} width={'auto'} height={'auto'} orientation="top" style={[{ alignItems: 'flex-start' }, GlobalStyles.horizontalPadding]} backgroundColor={theme.colors.transparentDark}>
            <TextIconButton theme={theme} color={menuActive === 'PRIVACY_POLICY' && theme.colors.accentColor || undefined} style={[GlobalStyles.width100, GlobalStyles.flexStart]} icon={{ iconSource: 'IonIcon', color: menuActive === 'PRIVACY_POLICY' && theme.colors.light || undefined, name: 'document-lock-outline' }} text={Menu.PrivacyPolicy.$title} onPress={() => menuActive === 'PRIVACY_POLICY' ? setMenuActive(true) : setMenuActive('PRIVACY_POLICY')} />
            <Separator />
            <TextIconButton theme={theme} color={menuActive === 'IMPRESSUM' && theme.colors.accentColor || undefined} style={[GlobalStyles.width100, GlobalStyles.flexStart]} icon={{ iconSource: 'IonIcon', color: menuActive === 'IMPRESSUM' && theme.colors.light || undefined, name: 'document-text-outline' }} text={Menu.Impressum.$title} onPress={() => menuActive === 'IMPRESSUM' ? setMenuActive(true) : setMenuActive('IMPRESSUM')} />
            <Separator />
            <TextIconButton theme={theme} color={swipePosition === 1 && scrollYPosition === 2 && theme.colors.accentColor || undefined} style={[GlobalStyles.width100, GlobalStyles.flexStart]} icon={{ iconSource: 'IonIcon', color: swipePosition === 1 && scrollYPosition === 2 && theme.colors.light || undefined, name: 'help-circle-outline' }} text={Questions.commonQuestions.$title} onPress={onQuestion} />
            <Separator />
            <TextIconButton theme={theme} style={[GlobalStyles.width100, GlobalStyles.flexStart]} icon={{ name: "language", iconSource: "IonIcon", size: DEFAULT_ICON_SIZE }} text={Menu.$language} onPress={onLanguage} increaseHitSlop={true} />
          </OverlayContainer>
        </>
      )}

      {displayScrollBackUpButton && (
        <OverlayContainer bottom={HEADER_HEIGHT} right={DEFAULT_HORIZONTAL_DISTANCE * 2} zIndex={1} backgroundColor={theme.colors.transparentLightAccent}>
          <ExpandableButton theme={theme} expanded={true} onPress={onScrollBackUp} size={DEFAULT_BIG_ICON_SIZE} />
        </OverlayContainer>
      )}
      <LanguageSelectionOverlay theme={theme} />
    </GestureHandlerRootView>
  )
}


interface WhyHowProps extends ThemeProps {
  GetQunai: [$title: string, onPress: () => void]
  $whyHowFooterTip: string
  onLogoPress: () => void
}
const WhyHowFooter: React.FC<WhyHowProps> = ({ theme, GetQunai, $whyHowFooterTip }) => (
  <>
    <ThemedButton theme={theme} text={GetQunai[0]} onPress={GetQunai[1]} />
    <View style={[GlobalStyles.width100, GlobalStyles.flexEnd]}>
      <Tip theme={theme} text={$whyHowFooterTip} />
    </View>
  </>
)

export default App;