import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './index.css'

/////////// Generate react-native-vector-icons and OpenSans font ///////////
// Generate the required CSS
import OpenSans from './client/assets/fonts/OpenSans-Regular.ttf'
import OpenSansBold from './client/assets/fonts/OpenSans-Bold.ttf'
import OpenSansLight from './client/assets/fonts/OpenSans-Light.ttf'
import IonIcons from 'react-native-vector-icons/Fonts/Ionicons.ttf';
import FontAwesome from 'react-native-vector-icons/Fonts/FontAwesome.ttf';
import FontAwesome5 from 'react-native-vector-icons/Fonts/FontAwesome5_Regular.ttf';
import FontAwesome5_Solid from 'react-native-vector-icons/Fonts/FontAwesome5_Solid.ttf';
import MaterialCommunity from 'react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf';
import Material from 'react-native-vector-icons/Fonts/MaterialIcons.ttf';
const iconFontStyles = `
@font-face {
  src: url(${OpenSans});
  font-family: OpenSans-Regular;
}
@font-face {
  src: url(${OpenSansBold});
  font-family: OpenSans-Bold;
}
@font-face {
  src: url(${OpenSansLight});
  font-family: OpenSans-Light;
}
@font-face {
  src: url(${IonIcons});
  font-family: IonIcons;
}
@font-face {
  src: url(${FontAwesome});
  font-family: FontAwesome;
}
@font-face {
  src: url(${FontAwesome5});
  font-family: FontAwesome5;
}
@font-face {
  src: url(${FontAwesome5_Solid});
  font-family: FontAwesome5_Solid;
}
@font-face {
  src: url(${MaterialCommunity});
  font-family: MaterialCommunityIcons;
}
@font-face {
  src: url(${Material});
  font-family: MaterialIcons;
}
`;
// Create a stylesheet
const style = document.createElement('style');
style.type = 'text/css';
// Append the iconFontStyles to the stylesheet
style.appendChild(document.createTextNode(iconFontStyles));
// Inject the stylesheet into the document head
document.head.appendChild(style);
/////////// Generate react-native-vector-icons and OpenSans font ///////////






ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
