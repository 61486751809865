import { useCallback } from "react"
import { useSharedValue } from "react-native-reanimated"

export type callback = (...data: any[]) => Promise<any> | any
const useCoordinatedCallbacks = (callbacks: callback[], deps?: React.DependencyList) => {
    const canExecute = useSharedValue(true)
    const createCallback = (callback: callback) => {
        return async (...data: any[]) => {
            if (canExecute.value) {
                canExecute.value = false
                const result = await callback(...data)
                canExecute.value = true
                return result
            }
        }
    }

    const _deps = deps || []

    return {
        coordinatedCallbacks: callbacks.map((callback) => useCallback(createCallback(callback), [canExecute, ..._deps])),
        canExecute
    }
}



export default useCoordinatedCallbacks