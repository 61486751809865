import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { Modal, TouchableOpacity, View } from "react-native";
import { DEFAULT_BIG_FONT_SIZE, screenHeight, screenWidth } from "../../../env";
import { ThemeProps } from "../../../styles/Theme";
import GlobalStyles from "../../../styles/global-styles";
import { ContentButton, GestureHandlerContentButton, ThemedButton } from "../Buttons";
import Icon from "../Icon";
import Separator from "../Separator";
import TextInput from "../TextInput";
import OverlayContainer from "./OverlayContainer";
import { FlatList } from "react-native-gesture-handler";
import StateAPI from "../../../apis/ui/StateAPI";


type setDisplay = Dispatch<SetStateAction<boolean>> | ((display: boolean) => void)
export interface DropdownListOverlayProps extends ThemeProps {
    onFinish: (text: any) => void
    setDisplay: setDisplay
}

type item = [name: string, value: any]
interface Props extends DropdownListOverlayProps {
    content: item[]
}

const numberOfRenderedItems = Math.ceil(screenHeight / DEFAULT_BIG_FONT_SIZE)

const getItemLayout = (data: unknown, index: number) => ({ length: DEFAULT_BIG_FONT_SIZE, offset: DEFAULT_BIG_FONT_SIZE * index, index })

const DropdownListOverlay = ({ theme, onFinish, setDisplay, content }: Props) => {
    const [search, setSearch] = useState('')
    const onSearch = (text: string) => {
        setSearch(text)
        setData(executeSearch(text).slice(0, numberOfRenderedItems))
    }
    const executeSearch = useCallback((text: string) => content.filter(item => item[0].toLowerCase().includes(text.toLowerCase())), [content])
    const dataSource = useMemo(() => search.length !== 0 ? executeSearch(search) : content, [content, search])
    const [data, setData] = useState<item[]>(dataSource.slice(0, numberOfRenderedItems))
    const renderMoreItems = useCallback(() => setData(dataSource.slice(0, data.length + numberOfRenderedItems)), [data, dataSource])

    const dismiss = () => setDisplay(false)
    return (
        <OverlayContainer
            top={0}
            left={0}
            zIndex={1/* before header */}
            width={screenWidth}
            height={screenHeight}
            dismiss={dismiss}
            backgroundColor={theme.colors.black}
        >
            <View style={[{ maxHeight: '80%', width: '70%' }]}>
                <View style={[GlobalStyles.flexBetween, theme.styles.backgroundDark, GlobalStyles.marginBottom, GlobalStyles.borderLeftRight, theme.styles.borderLight, GlobalStyles.borderRadius, GlobalStyles.horizontalPadding]}>
                    <TextInput
                        theme={theme}
                        value={search}
                        placeholder="..."
                        onChangeText={onSearch}
                        customStyle={GlobalStyles.flex}
                    />
                    <Icon iconSource='IonIcon' name="search-circle-outline" color={theme.colors.layoutColor} />
                </View>

                <FlatList
                    data={data}
                    extraData={[theme, search]}

                    renderItem={(item) => <Item theme={theme} setDisplay={setDisplay} text={item.item[0]} onPress={() => onFinish(item.item)} />}
                    getItemLayout={getItemLayout} // FlatList
                    // estimatedItemSize={DEFAULT_BIG_FONT_SIZE} // FlashList

                    onEndReached={renderMoreItems}
                    onEndReachedThreshold={0.4}
                    // onScrollEndDrag={renderMoreItems}

                    showsVerticalScrollIndicator={false}
                />
            </View>
        </OverlayContainer >
    )
}



interface ItemProps extends ThemeProps {
    text: string
    onPress: (text: string) => void
    setDisplay: setDisplay
}
const Item = ({ theme, text, onPress, setDisplay }: ItemProps) => (
    <>
        <ThemedButton
            theme={theme}
            onPress={() => {
                onPress(text)
                setDisplay(false)
            }}
            text={{ text, style: [GlobalStyles.heading, GlobalStyles.paddingTop] }}
        />
        <Separator />
    </>
);


export default React.memo(DropdownListOverlay)