import { StyleSheet } from "react-native";
import { CSPRNG } from "../apis/qunai";
import { color, personalTheme } from "../apis/storage/MemberStorage";
import { ACCENT_COLOR_MAX_RELATIVE_LUMINANCE_IN_LIGHT_MODE, ACCENT_COLOR_MIN_RELATIVE_LUMINANCE, DARK_LIGHT_CONTRAST_RELATIVE_LUMINANCE, DEFAULT_ACCENT_COLOR, DEFAULT_FONT, DEFAULT_LAYOUT_COLOR, LAYOUT_COLOR_MAX_RELATIVE_LUMINANCE, LAYOUT_COLOR_MIN_RELATIVE_LUMINANCE_IN_DARK_MODE } from "../env";
import GlobalStyles from "./global-styles";


type themeStyles = {
    textLight: {
        color: color
    },
    textLightAccent: {
        color: color
    },
    textDark: {
        color: color
    }
    textAccentColor: {
        color: color;
    };
    textLayoutColor: {
        color: color;
    };

    borderLight: {
        borderColor: color
    },
    borderDark: {
        borderColor: color
    },
    borderLayoutColor: {
        borderColor: color;
    };
    borderAccentColor: {
        borderColor: color;
    };

    backgroundLight: {
        backgroundColor: color
    },
    backgroundTransparentLight: {
        backgroundColor: color
    }
    backgroundDark: {
        backgroundColor: color
    },
    backgroundTransparentDark: {
        backgroundColor: color
    }
    backgroundLayoutColor: {
        backgroundColor: color;
    };
    backgroundAccentColor: {
        backgroundColor: color
    };
}
type themeColors = {
    light: color
    lightAccent: color
    transparentLight: color
    transparentLightAccent: color
    white: color
    dark: color
    darkAccent: color
    transparentDark: color
    transparentDarkAccent: color
    black: color

    accentColor: color
    accentColorAccent: color
    layoutColor: color
    layoutColorAccent: color
}

export interface ThemeProps {
    theme: Theme
}



interface ColorObj {
    R: number,
    G: number,
    B: number
}

////////////////////////////// Theme API managing application wide theming //////////////////////////////
////////////////////////////// All logic concerning theming within the app is dark mode based --> main colors for dark mode are light and secondary colors are dark //////////////////////////////
////////////////////////////// IMPORTANT: Layout- and Accentcolor have to be hex or rgb!!!(because of the getRelativeLuminance and ColorObj implementations) //////////////////////////////


export default class Theme {
    styles: themeStyles
    colors: themeColors
    type: 'dark' | 'light'

    static readonly defaultPersonalTheme = { useDarkMode: true, layoutColor: DEFAULT_LAYOUT_COLOR, accentColor: DEFAULT_ACCENT_COLOR }
    static readonly statics = {
        wideScreenWidth: '1280px', /*more than 1280px*/
        tabletScreenWidth: '920px',/*less than 920px*/
        mobileScreenWidth: '580px',/*more than 580px*/

        // rootFontSize: '1.01em',
        // rootFontSizeTablet: ' calc(var(root-font-size) * 1.01)',
        // rootFontSizeDesktop: ' calc(var(root-font-size) * 1.015)',


        LIGHT_COLOR: '#BFBFBF',
        LIGHT_ACCENT_COLOR: '#959595',
        TRANSPARENT_LIGHT_COLOR: 'rgba(191,191,191, 0.775)', // 0.6
        TRANSPARENT_LIGHT_ACCENT_COLOR: 'rgba(0, 0, 0, 0.575)', // 0.4
        white: '#ffffff',
        DARK_COLOR: '#131313', // has to be rgb or hex - gets used in ForegRoundServiceAPI
        DARK_ACCENT_COLOR: '#282828',
        TRANSPARENT_DARK_COLOR: 'rgba(19,19,19, 0.725)', // 0.45
        TRANSPARENT_DARK_ACCENT_COLOR: 'rgba(255, 255, 255, 0.725)', // 0.45
        black: '#000000',

        ok: '#136729',
        error: 'rgba(184, 3, 3, 0.85)', // 0.562
        red: '#FF0000',
        link: '#0000EE',
        warn: 'rgba(249, 174, 34, 0.85)',

        purpleGradient: [DEFAULT_ACCENT_COLOR, DEFAULT_LAYOUT_COLOR] as const,
        greyGradient: ['#859398', '#7E8183', '#222846'] as const,
        basicGradient: ['#A8E063', '#56AB2F'] as const,
        networkGradient: ['#FF0099', '#41295A'] as const,
        visionaryGradient: ['#5484D8', '#1E3C72'] as const,

        animationColors: ['#06AE00', '#44AE00', '#A6AE1A', '#AE5F00', '#AE2D00', '#B50200', '#B50025', '#B5243E', '#B50962', '#B518AE', '#6300C2', '#3000C2', '#000AC2', '#0034C2', '#1A69C6', '#00B176', '#00B12E', '#00C21C',] as const,
    }

    constructor(personalTheme: personalTheme) {
        const { useDarkMode, accentColor, layoutColor } = personalTheme || Theme.defaultPersonalTheme //phone theme??
        if (useDarkMode) {
            this.type = 'dark'
            this.colors = {
                light: Theme.statics.LIGHT_COLOR,
                lightAccent: Theme.statics.LIGHT_ACCENT_COLOR,
                transparentLight: Theme.statics.TRANSPARENT_LIGHT_COLOR,
                transparentLightAccent: Theme.statics.TRANSPARENT_LIGHT_ACCENT_COLOR,
                white: Theme.statics.LIGHT_COLOR,
                dark: Theme.statics.DARK_COLOR,
                darkAccent: Theme.statics.DARK_ACCENT_COLOR,
                transparentDark: Theme.statics.TRANSPARENT_DARK_COLOR,
                transparentDarkAccent: Theme.statics.TRANSPARENT_DARK_ACCENT_COLOR,
                black: Theme.statics.DARK_COLOR,

                accentColor,//#e01fc0 //'#FFD700',//'#00dacfd7',
                accentColorAccent: Theme.getAdjacentThemeTypeColor(accentColor),
                layoutColor,//'#260047', //e01fc0
                layoutColorAccent: Theme.getAdjacentThemeTypeColor(layoutColor)
            }
        }
        else {
            this.type = 'light'
            this.colors = {
                light: Theme.statics.DARK_COLOR,
                lightAccent: Theme.statics.DARK_ACCENT_COLOR,
                transparentLight: Theme.statics.TRANSPARENT_DARK_COLOR,
                transparentLightAccent: Theme.statics.TRANSPARENT_DARK_ACCENT_COLOR,
                white: Theme.statics.DARK_COLOR,
                dark: Theme.statics.LIGHT_COLOR,
                darkAccent: Theme.statics.LIGHT_ACCENT_COLOR,
                transparentDark: Theme.statics.TRANSPARENT_LIGHT_COLOR,
                transparentDarkAccent: Theme.statics.TRANSPARENT_LIGHT_ACCENT_COLOR,
                black: Theme.statics.LIGHT_COLOR,

                accentColor: accentColor,//#e01fc0 //'#FFD700',//'#00dacfd7',
                accentColorAccent: Theme.getAdjacentThemeTypeColor(accentColor),
                layoutColor: layoutColor,//'#260047', //e01fc0
                layoutColorAccent: Theme.getAdjacentThemeTypeColor(layoutColor)
            }
        }


        this.styles = StyleSheet.create({
            textLight: {
                color: this.colors.light,
                ...GlobalStyles.text
            },
            textLightAccent: {
                color: this.colors.lightAccent,
                ...GlobalStyles.text
            },
            textDark: {
                color: this.colors.dark,
                ...GlobalStyles.text
            },
            textAccentColor: {
                color: this.colors.accentColor,
                ...GlobalStyles.text
            },
            textLayoutColor: {
                color: this.colors.layoutColor,
                ...GlobalStyles.text
            },

            borderLight: {
                borderColor: this.colors.light
            },
            borderDark: {
                borderColor: this.colors.dark
            },
            borderLayoutColor: {
                borderColor: this.colors.layoutColor
            },
            borderAccentColor: {
                borderColor: this.colors.accentColor
            },

            backgroundLight: {
                backgroundColor: this.colors.light
            },
            backgroundTransparentLight: {
                backgroundColor: this.colors.transparentLight
            },
            backgroundDark: {
                backgroundColor: this.colors.dark
            },
            backgroundTransparentDark: {
                backgroundColor: this.colors.transparentDark
            },
            backgroundLayoutColor: {
                backgroundColor: this.colors.layoutColor
            },
            backgroundAccentColor: {
                backgroundColor: this.colors.accentColor
            },
        })
    }


    // Isnt used at all, just here for efficiency
    static getChatMembers = (memberNames: string[], name: string, accentColor: color) => { // this is not considering potentially set accentColors of members yet
        const existingIndexes: number[] = []
        const userArr: { name: string, color: string }[] = []
        let i = 0

        const _accentColor = this.convertColorToColorObj(accentColor)


        while (i < memberNames.length) {
            let index = CSPRNG.generateUnsafeRandomInteger(0, this.statics.animationColors.length - 1)
            const colorIndex = this.convertColorToColorObj(Theme.statics.animationColors[index]!)
            const colorIsSimilarToAccentColor = !this.colorsAreSimilar(colorIndex, _accentColor, 92)
            const colorIsSimilarToColorInArray = userArr.find((user) => {
                const userColor = this.convertColorToColorObj(user.color)
                return this.colorsAreSimilar(userColor, colorIndex)
            })
            if (!(existingIndexes.find(_i => _i === index) && colorIsSimilarToAccentColor && colorIsSimilarToColorInArray)) {
                existingIndexes.push(index)
                userArr.push({ name: memberNames[i]!, color: memberNames[i]! === name ? accentColor : Theme.statics.animationColors[index]! })
                i++
            }
        }
        return userArr
    }

    // Taking color objs, this function returns a unique color for a new obj, that hasnt set a layoutColor
    static getUniqueColor = <T extends { layoutColor: color, accentColor: color }>(objs: T[], isLayoutColor: boolean, usesDarkMode: boolean) => {
        const nonUniqueIndexes: number[] = []
        let index = 0
        while (true) {
            index = CSPRNG.generateUnsafeRandomInteger(0, this.statics.animationColors.length - 1)
            if (nonUniqueIndexes.find(i => i === index)) return // index === non-unique index

            const hexIndexColor = Theme.statics.animationColors[index]!
            const indexColor = this.convertColorToColorObj(hexIndexColor)
            const colorIsSimilarToColorInArray = objs.find((member) => {
                const color = this.convertColorToColorObj(isLayoutColor ? member.layoutColor : member.accentColor)
                return this.colorsAreSimilar(color, indexColor)
            })
            if (colorIsSimilarToColorInArray) nonUniqueIndexes.push(index) // register non-unique index
            else if (isLayoutColor ? !this.isLayoutColor(hexIndexColor, usesDarkMode) : !this.isAccentColor(hexIndexColor, usesDarkMode)) nonUniqueIndexes.push(index) // register non-unique index
            else break; // unique index found
        }
        return Theme.statics.animationColors[index]
    }


    // Determines wether the input color color is a valid AccentColor
    static isAccentColor = (color: color, useDarkMode: boolean) => {
        const c = this.convertColorToColorObj(color)
        const relativeLuminance = this.getRelativeLuminance(c)
        // console.log('AccentColor',  relativeLuminance, ACCENT_COLOR_MIN_RELATIVE_LUMINANCE, relativeLuminance > ACCENT_COLOR_MIN_RELATIVE_LUMINANCE, !useDarkMode ? relativeLuminance < ACCENT_COLOR_MAX_RELATIVE_LUMINANCE_IN_LIGHT_MODE : true, relativeLuminance > ACCENT_COLOR_MIN_RELATIVE_LUMINANCE && (!useDarkMode ? relativeLuminance < ACCENT_COLOR_MAX_RELATIVE_LUMINANCE_IN_LIGHT_MODE : true))
        if (relativeLuminance > ACCENT_COLOR_MIN_RELATIVE_LUMINANCE && (!useDarkMode ? relativeLuminance < ACCENT_COLOR_MAX_RELATIVE_LUMINANCE_IN_LIGHT_MODE : true)) return true
        return false
    }

    // Determines wether the input color color is a valid LayoutColor
    static isLayoutColor = (color: color, useDarkMode: boolean) => {
        const c = this.convertColorToColorObj(color)
        const relativeLuminance = this.getRelativeLuminance(c)
        // console.log('LayoutColor', relativeLuminance, LAYOUT_COLOR_MAX_RELATIVE_LUMINANCE, relativeLuminance < LAYOUT_COLOR_MAX_RELATIVE_LUMINANCE, useDarkMode ? relativeLuminance > LAYOUT_COLOR_MIN_RELATIVE_LUMINANCE_IN_DARK_MODE : true, relativeLuminance < LAYOUT_COLOR_MAX_RELATIVE_LUMINANCE && (useDarkMode ? relativeLuminance > LAYOUT_COLOR_MIN_RELATIVE_LUMINANCE_IN_DARK_MODE : true))
        if (relativeLuminance < LAYOUT_COLOR_MAX_RELATIVE_LUMINANCE && (useDarkMode ? relativeLuminance > LAYOUT_COLOR_MIN_RELATIVE_LUMINANCE_IN_DARK_MODE : true)) return true
        return false
    }

    static getAdjacentThemeTypeColor = (color: color) => {
        if (this.getRelativeLuminance(this.convertColorToColorObj(color)) < DARK_LIGHT_CONTRAST_RELATIVE_LUMINANCE) {
            return Theme.statics.LIGHT_COLOR
        }
        else {
            return Theme.statics.DARK_COLOR
        }
    }

    // Returns the relative luminance of a color: https://en.wikipedia.org/wiki/Relative_luminance, https://stackoverflow.com/questions/52879235/determine-color-lightness-via-rgb
    private static getRelativeLuminance(c: ColorObj) {
        const normalizedColorObj = {
            R: c.R / 255,
            G: c.G / 255,
            B: c.B / 255
        }
        return (0.2126 * normalizedColorObj.R + 0.7152 * normalizedColorObj.G + 0.0722 * normalizedColorObj.B)
    }

    // Converts an input color color to a ColorObj
    static convertColorToColorObj(color: color) {
        if (rgbRegex.test(color)) { // rgb
            const [R, G, B] = color.replace('rgb(', '').replace(')', '').replace(/\s/g, '').split(',').map(str => parseInt(str))
            return { R, G, B } as ColorObj
        }
        else { // is hex color
            const R = parseInt(color.substring(1, 3), 16)
            const G = parseInt(color.substring(3, 5), 16)
            const B = parseInt(color.substring(5, 7), 16)
            return { R, G, B } as ColorObj
        }
    }

    private static convertColorObjElementToHexElement(R_or_G_or_B: number) {
        const hex = R_or_G_or_B.toString(16);
        return hex.length == 1 ? "0" + hex : hex;
    }
    static convertColorToHex(color: color) {
        if (rgbRegex.test(color)) {
            const obj = this.convertColorToColorObj(color)
            return '#' + this.convertColorObjElementToHexElement(obj.R) + this.convertColorObjElementToHexElement(obj.G) + this.convertColorObjElementToHexElement(obj.B)
        }
        else return color
    }

    // Determines if 2 colors are similar taking in a tolerance value
    private static colorsAreSimilar = (c1: ColorObj, c2: ColorObj, tolerance: number = 80) => {
        return Math.abs(c1.R - c2.R) < tolerance &&
            Math.abs(c1.G - c2.G) < tolerance &&
            Math.abs(c1.B - c2.B) < tolerance
    }


    private static getRandomRGBColor() {
        return CSPRNG.generateUnsafeRandomInteger(0, 255)
    }
    static getRandomThemeColors() {
        const layoutColor = `rgb(${this.getRandomRGBColor()},${this.getRandomRGBColor()},${this.getRandomRGBColor()})`
        const accentColor = `rgb(${this.getRandomRGBColor()},${this.getRandomRGBColor()},${this.getRandomRGBColor()})`
        return { layoutColor, accentColor }
    }
}


const rgbRegex = /^rgb\(((\d{1,3}),){2}(\d{1,3})\)$/




// export const getAdjacentThemeTypeColor = function (str: string) {
//     switch (str.toLowerCase()) {
//         case "white":
//             str = "#FFFFFF";
//             break;
//         case "black":
//             str = "#000000"
//     }

//     var rgb = [0, 0, 0];
//     rgb[0] = parseInt(str.substring(1, 3), 16);
//     rgb[1] = parseInt(str.substring(3, 5), 16);
//     rgb[2] = parseInt(str.substring(5, 8), 16);

//     const brightness = Math.round(((rgb[0] * 299) + (rgb[1] * 587) + (rgb[2] * 114)) / 1000);

//     if (brightness > 125) {
//         return "#000000"
//     } else {
//         return "#ffffff"
//     }
// }