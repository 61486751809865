import React, { PropsWithChildren } from "react"
import Animated, { Easing, ReduceMotion, WithTimingConfig, useAnimatedKeyboard, useAnimatedReaction, useAnimatedStyle, useSharedValue, withTiming } from "react-native-reanimated"
import GlobalStyles from "../../styles/global-styles"


export const keyboardAvoidingAnimationConfig: WithTimingConfig = {
    duration: 200,
    easing: Easing.out(Easing.sin),
    reduceMotion: ReduceMotion.Never,
}

// const KeyboardAvoidingView: React.FC<PropsWithChildren<{}>> = ({ children }) => {
//     const keyboard = useAnimatedKeyboard();
//     const offset = useSharedValue(0)


//     const style = useAnimatedStyle(() => {
//         return keyboardOut ? { height: withTiming(keyboard.height.value - offset.value + screenHeight * 0.1, keyboardAvoidingAnimationConfig) } : { height: withTiming(0, keyboardAvoidingAnimationConfig) }
//     })

//     const ref = useRef<Animated.View>(null)
//     useLayoutEffect(() => {
//         ref.current?.measureInWindow((x, y, w, h) => {
//             offset.value = screenHeight - y
//             // console.log({ offset: offset.value, y, children, result: keyboardHeight - offset.value + screenHeight * 0.1 })
//         })
//     }, [ref, keyboardHeight])

//     // console.log({ result: keyboardHeight - offset.value + screenHeight * 0.1, offset})

//     return (
//         <>
//             {children}
//             <Animated.View
//                 ref={ref}
//                 style={[style]}
//             />
//         </>
//     )
// }
interface Props {
    bottomOffset?: number
    additionalCondition?: boolean
}

export const KeyboardAvoidingViewWithTranslation: React.FC<PropsWithChildren<Props>> = ({ bottomOffset = 0, additionalCondition = true, children }) => {
    const keyboard = useAnimatedKeyboard({ isStatusBarTranslucentAndroid: true });
    const keyboardHeight = useSharedValue(keyboard.height.value)
    useAnimatedReaction(
        () => keyboard.height.value,
        (currentValue, previousValue) => {
            if (previousValue && currentValue && currentValue >= previousValue) keyboardHeight.value = bottomOffset - keyboard.height.value // opening
            else return keyboardHeight.value = keyboard.height.value
        }
    )

    const style = useAnimatedStyle(() => {
        return { transform: [{ translateY: additionalCondition ? withTiming(keyboardHeight.value, keyboardAvoidingAnimationConfig) : withTiming(0, keyboardAvoidingAnimationConfig) }] }
    })

    return (
        <Animated.View style={[GlobalStyles.width100, GlobalStyles.height100, style]}>
            {children}
        </Animated.View>
    )
}

interface WithHeightProps {
    height: number
    additionalCondition?: boolean
    offset?: number
}

export const KeyboardAvoidingViewWithHeight: React.FC<PropsWithChildren<WithHeightProps>> = ({ height, additionalCondition = true, offset = 0, children }) => {
    const keyboard = useAnimatedKeyboard({ isStatusBarTranslucentAndroid: true });
    const keyboardHeight = useSharedValue(keyboard.height.value)
    useAnimatedReaction(
        () => keyboard.height.value,
        (currentValue, previousValue) => {
            if (previousValue && currentValue && currentValue >= previousValue) keyboardHeight.value = keyboard.height.value - offset // opening
            else return keyboardHeight.value = keyboard.height.value
        }
    )


    const style = useAnimatedStyle(() => {
        return { height: additionalCondition ? withTiming(height - keyboardHeight.value, keyboardAvoidingAnimationConfig) : withTiming(height, keyboardAvoidingAnimationConfig) }
    })

    return (
        <Animated.View style={[GlobalStyles.width100, style]}>
            {children}
        </Animated.View>
    )
}