import React, { useEffect, useState } from "react";
import StateAPI from "../../../apis/ui/StateAPI";
import LanguageAPI from "../../../apis/ui/LanguageAPI";
import { ThemeProps } from "../../../styles/Theme";
import DropdownListOverlay from "./DropdownListOverlay";



const LanguageSelectionOverlay: React.FC<ThemeProps> = ({ theme }) => {
    const [display, setDisplay] = useState(false)
    useEffect(() => {
        const initial = StateAPI.setDisplayLanguageSelection
        StateAPI.setDisplayLanguageSelection = (display) => {
            initial(display)
            setDisplay(display)
        }

        return () => {
            StateAPI.setDisplayLanguageSelection = initial
        }
    }, [])

    if (display) return <DropdownListOverlay theme={theme} setDisplay={StateAPI.setDisplayLanguageSelection} content={LanguageAPI.getSupportedLanguages()} onFinish={LanguageAPI.changeLanguage} />
}



export default React.memo(LanguageSelectionOverlay)